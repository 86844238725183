import {gql, useMutation, useQuery, useLazyQuery} from "@apollo/client";
import React, {useState, useContext} from "react";
import {DateTime} from "luxon";
import styled, { keyframes, css } from 'styled-components';
import { FaPaperPlane, FaExchangeAlt, FaShare, FaCog } from 'react-icons/fa';
import {DateTimeInput, LoadingView, StateStack, Tabs, NotificationContext, AutoExpandingTextarea} from "@solsys/solsys-reactjs";
import Chat from './Chat'

const serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

// Basic Styles and Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const neonGlow = keyframes`
  from { box-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 15px #0ff, 0 0 20px #00ffff, 0 0 25px #00ffff, 0 0 30px #00ffff, 0 0 35px #00ffff; }
  to { box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #00ffff, 0 0 50px #00ffff, 0 0 60px #00ffff, 0 0 70px #00ffff; }
`;

const baseButtonStyle = css`
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0ff;
  background: transparent;
  border: 1px solid #0ff;

  &:hover {
    animation: ${neonGlow} 1.5s alternate infinite;
  }

  svg {
    margin-right: 5px;
  }
`;

// Chat Container
const StyledChatContainer = styled.div`
  background-color: rgba(10, 25, 47, 0.9); // Translucent background
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5); // Neon glow effect
  color: #0ff;
`;

// Message Component
const StyledMessage = styled.div`
  background-color: rgba(255, 255, 255, 0.1); // Semi-transparent messages
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  animation: ${fadeIn} 0.3s ease-out;
  border: 1px solid #0ff;
`;

// Input Box
const StyledInputBox = styled.div`
  display: flex;
  margin-top: 10px;
`;

const StyledTextarea = styled.textarea`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0ff;
  background-color: rgba(10, 25, 47, 0.8);
  color: #0ff;
  resize: none;
`;

// Control Panel
const ControlPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(10, 25, 47, 0.8); // Matching the container
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const StyledButton = styled.button`
  ${baseButtonStyle}
  background-color: transparent;
  color: #0ff; // Neon-like cyan color
  border: 1px solid #0ff;
  font-size: 16px;

  &:hover {
    animation: ${neonGlow} 1.5s alternate infinite;
  }

  svg {
    margin-right: 5px;
    font-size: 1.2em;
  }
`;

const Message = ({ text, timestamp }) => (
  <StyledMessage>
    <div>{text}</div>
    <small>{timestamp}</small>
  </StyledMessage>
);

export default function ProjectEdit({id: entityId, action, navigate}) {
  const isEdit = action === 'edit'
  
  const {addNotification} = useContext(NotificationContext)
  const errorHandler = (error) => {
    console.error(error);
    addNotification('bad', 'Something went wrong')
  }

  let [title, setTitle] = useState(null)
  let [scope, setScope] = useState(null)
  let [website, setWebsite] = useState(null)
  let [beneficiary, setBeneficiary] = useState(null)
  let [beneficiaryCoverage, setBeneficiaryCoverage] = useState(null)
  let [status, setStatus] = useState(null)
  let [startDate, setStartDate] = useState(null)
  let [endDate, setEndDate] = useState(null)
  let [objective, setObjective] = useState(null)
  let [description, setDescription] = useState(null)
  let [fundingSource, setFundingSource] = useState(null)
  let [professionals, setProfessionals] = useState(null)
  let [contacts, setContacts] = useState([])
  let [clinicalEquipment, setClinicalEquipment] = useState(null)
  let [medicalPhysicsEquipment, setMedicalPhysicsEquipment] = useState(null)
  let [equipmentComments, setEquipmentComments] = useState(null)
  let [clinicalAddress, setClinicalAddress] = useState({})
  let [research, setResearch] = useState(null)
  let [researchComments, setResearchComments] = useState(null)
  let [qualityAssurance, setQualityAssurance] = useState(null)
  let [humanResources, setHumanResources] = useState(null)
  let [sufficientFunds, setSufficientFunds] = useState(null)
  let [aidImportingDonatedItems, setAidImportingDonatedItems] = useState(null)
  let [humanResourcesComments, setHumanResourcesComments] = useState(null)
  let [languagePreference, setLanguagePreference] = useState(null)
  
  const [messages, setMessages] = useState([
    { type: 'message', text: 'Welcome to the chat!', username: 'Admin', timestamp: new Date().toISOString() },
    { type: 'message', text: 'Feel free to change entity properties.', username: 'Admin', timestamp: new Date().toISOString() }
  ]);

  const handleSendMessage = (message) => {
    setMessages([...messages, { type: 'message', text: message, username: 'User', timestamp: new Date().toISOString() }]);
  };

  const handleEntityChange = (property, value) => {
    setMessages([...messages, { type: 'change', property, value, username: 'User', timestamp: new Date().toISOString(), comment: `Changed ${property} to ${value}` }]);
  };

  const loadingEntity = false
  const loadingMutation = false
  
  return loadingEntity ? <LoadingView/> : (
    <>
      <div className="page-header">
        <span className="page-header-title">{`${isEdit ? "Edit" : "Add"} Project`}</span>
      </div>

      <div className="add-edit">
        
        <div className="edit-section">
          <div className="edit-property">
            <label>Title:</label>
            <input
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          
          <div className="edit-property">
            <label>Website:</label>
            <input
              value={website}
              onChange={e => setWebsite(e.target.value)}
            />
          </div>
          
        </div>
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Scope:</label>
            <select
              value={scope}
              onChange={e => setScope(e.target.value)}
            >
              <option/>
              <option value={1}>Education & Training</option>
              <option value={2}>Planning/Setting up a new radiation therapy/radiology center or service line</option>
              <option value={3}>Upgrading an existing radiation therapy/radiology center or service line</option>
              <option value={4}>Clinical program development/improvement</option>
              <option value={5}>Clinical support through visiting faculty</option>
              <option value={6}>Clinical support through full time faculty on the ground</option>
              <option value={7}>Medical physics, clinician, and technologist programs</option>
              <option value={8}>Supporting Ministry of Health in developing radiation program</option>
              <option value={9}>Research & Development</option>
              <option value={10}>Equipment transfer/ donation</option>
              <option value={11}>Other (specify)</option>
            </select>
          </div>
          
        </div>
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Beneficiary:</label>
            <select
              value={beneficiary}
              onChange={e => setBeneficiary(e.target.value)}
            >
              <option/>
              <option value={1}>Global</option>
              <option value={2}>Single Institution</option>
              <option value={3}>Upgrading an existing radiation therapy/radiology center or service line</option>
              <option value={4}>National (country)</option>
              <option value={5}>Regional</option>
              <option value={6}>Please specify project beneficiary including Name of Institution(s), City, Country</option>
            </select>
          </div>
          
        </div>
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Beneficiary coverage:</label>
            <input
              value={beneficiaryCoverage}
              onChange={e => setBeneficiaryCoverage(e.target.value)}
            />
          </div>
          
        </div>
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Status:</label>
            <select
              value={status}
              onChange={e => setStatus(e.target.value)}
            >
              <option/>
              <option value={1}>Ongoing</option>
              <option value={2}>Closed</option>
              <option value={3}>On-Hold</option>
              <option value={4}>In Planning</option>
              <option value={5}>Other</option>
            </select>
          </div>
          
        </div>
        <div className="edit-section">
        
          <div className="edit-property">
            <label>Start Date:</label>
            <DateTimeInput
              dateOnly
              timeZone='America/New_York'
              value={startDate}
              onChange={date => {
                setStartDate(date)
              }}
            />
          </div>
          <div className="edit-property">
            <label>End Date:</label>
            <DateTimeInput
              dateOnly
              timeZone='America/New_York'
              value={endDate}
              onChange={date => {
                setEndDate(date)
              }}
            />
          </div>
          
        </div>
          
        <div className="edit-property">
          <label>Objective:</label>
          <input
            style={{width: "100%"}}
            value={objective}
            onChange={e => setObjective(e.target.value)}
          />
        </div>
          
        <div className="edit-property">
          <label>Description:</label>
          <AutoExpandingTextarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
          
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Sources of funding:</label>
            <select
              value={fundingSource}
              onChange={e => setFundingSource(e.target.value)}
            >
              <option/>
              <option value={1}>Department</option>
              <option value={2}>Institution</option>
              <option value={3}>Grant</option>
              <option value={4}>Fundraising</option>
              <option value={5}>Other (specify)</option>
            </select>
          </div>
          
          <div className="edit-property">
            <label>Professionals involved:</label>
            <select
              value={professionals}
              onChange={e => setProfessionals(e.target.value)}
            >
              <option/>
              <option value={1}>Radiation oncologist</option>
              <option value={2}>Medical physicist</option>
              <option value={3}>Therapy radiographers</option>
              <option value={4}>Radiation biologist</option>
              <option value={5}>Student</option>
              <option value={6}>Medical oncologist</option>
              <option value={7}>Surgical oncologist</option>
              <option value={8}>Radiologist</option>
              <option value={9}>RT tech</option>
              <option value={10}>Radiology tech</option>
              <option value={11}>DIT specialist</option>
              <option value={12}>Other (specify)</option>
            </select>
          </div>
          
        </div>
        
        <h4>Contact Info</h4>
        
        {contacts.map((contact, index) => (
          <React.Fragment key={index}>
            {index > 0 &&
              <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>
            }
            <div className="edit-section">
              <div className="edit-property">
                <label>Name:</label>
                <input
                  value={contact.name}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].name = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Email:</label>
                <input
                  value={contact.email}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].email = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
            </div>
            <div className="edit-section">
              <div className="edit-property">
                <label>Institution Name:</label>
                <input
                  value={contact.institutionName}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionName = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Address:</label>
                <input
                  value={contact.institutionAddress}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionAddress = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>City:</label>
                <input
                  value={contact.institutionCity}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionCity = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Country:</label>
                <input
                  value={contact.institutionCountry}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionCountry = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <label>Other preferred contact method:</label>
              <AutoExpandingTextarea
                value={contact.otherContact}
                onChange={e => {
                  let newContacts = [...contacts]
                  newContacts[index].otherContact = e.target.value
                  setContacts(newContacts)
                }}
              />
            </div>
          </React.Fragment>
        ))}
          
        <button onClick={() => {
          let newContacts = [...contacts]
          newContacts.push({name: ""})
          setContacts(newContacts)
        }}>Add Contact</button>
        
        <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>
        
        <div className="edit-section">
        
          <div className="edit-property">
            <label>Clinical equipment:</label>
            <select
              value={clinicalEquipment}
              onChange={e => setClinicalEquipment(e.target.value)}
            >
              <option/>
              <option value={1}>External Beam Therapy</option>
              <option value={2}>Brachytherapy</option>
              <option value={3}>CT-simulator</option>
              <option value={4}>Conventional simulator</option>
              <option value={5}>Treatment planning system TPS</option>
              <option value={6}>Planar-only gamma cameras</option>
              <option value={7}>SPECT gamma cameras</option>
              <option value={8}>SPECT-CT gamma cameras</option>
              <option value={9}>Cyclotron</option>
              <option value={10}>Other (specify)</option>
            </select>
          </div>
          
        </div>
        <div className="edit-section">
          
          <div className="edit-property">
            <label>Medical physics equipment:</label>
            <select
              value={medicalPhysicsEquipment}
              onChange={e => setMedicalPhysicsEquipment(e.target.value)}
            >
              <option/>
              <option value={1}>Ion Chamber</option>
              <option value={2}>Barometer</option>
              <option value={3}>Breast board</option>
              <option value={4}>Densitometer</option>
              <option value={5}>Electrometer</option>
              <option value={6}>Extension cable</option>
              <option value={7}>External beam chamber</option>
              <option value={8}>Imaging chamber</option>
              <option value={9}>Imaging test tool</option>
              <option value={10}>kVp meter</option>
              <option value={11}>Oscilloscope</option>
              <option value={12}>Phantoms</option>
              <option value={13}>Scanning system</option>
              <option value={14}>Shielding</option>
              <option value={15}>Survey meter (GM counter)</option>
              <option value={16}>Thermometer</option>
              <option value={17}>Timer</option>
              <option value={18}>Water tank</option>
              <option value={19}>Well-Ionization chamber</option>
              <option value={20}>Other (specify)</option>
            </select>
          </div>
          
        </div>
          
        <div className="edit-property">
          <label>Equipment Comments:</label>
          <AutoExpandingTextarea
            value={equipmentComments}
            onChange={e => setEquipmentComments(e.target.value)}
          />
        </div>
        
        
        <h4>Clinical address (if different from the institution)</h4>
        <div className="edit-section">
          <div className="edit-property">
            <label>Address</label>
            <input
              value={clinicalAddress.address}
              onChange={e => setClinicalAddress({...clinicalAddress, address: e.target.value})}
            />
          </div>
        </div>
        <div className="edit-section">
          <div className="edit-property">
            <label>City</label>
            <input
              value={clinicalAddress.city}
              onChange={e => setClinicalAddress({...clinicalAddress, city: e.target.value})}
            />
          </div>
        </div>
        <div className="edit-section">
          <div className="edit-property">
            <label>Country</label>
            <input
              value={clinicalAddress.country}
              onChange={e => setClinicalAddress({...clinicalAddress, country: e.target.value})}
            />
          </div>
        </div>
        
        <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>
        
        <div className="edit-section">
          <div className="edit-property">
            <label>Research:</label>
            <select
              value={research}
              onChange={e => setResearch(e.target.value)}
            >
              <option/>
              <option value={1}>Clinical trials</option>
              <option value={2}>Patterns of care studies</option>
              <option value={3}>Other (specify)</option>
            </select>
          </div>
          
        </div>
          
        <div className="edit-property">
          <label>Comments on research:</label>
          <AutoExpandingTextarea
            value={researchComments}
            onChange={e => setResearchComments(e.target.value)}
          />
        </div>
        
        <div className="edit-section">
          <div className="edit-property">
            <label>Quality Assurance:</label>
            <select
              value={qualityAssurance}
              onChange={e => setQualityAssurance(e.target.value)}
            >
              <option/>
              <option value={1}>Remote Services, e.g. Reporting, treatment planning</option>
              <option value={2}>Peer review</option>
              <option value={3}>Commissioning</option>
              <option value={4}>Planning</option>
              <option value={5}>Other (specify)</option>
            </select>
          </div>
        </div>
        
        <h4>Human Resources</h4>
        <div className="edit-section">
          <div className="edit-property">
            <label>Resources:</label>
            <select
              value={humanResources}
              onChange={e => setHumanResources(e.target.value)}
            >
              <option/>
              <option value={1}>Education resources</option>
              <option value={2}>Clinical procedures</option>
              <option value={3}>Long-term training</option>
              <option value={4}>Short-term training</option>
              <option value={5}>On-site support</option>
              <option value={6}>Telemedicine</option>
              <option value={7}>Continuing Medical</option>
              <option value={8}>Education (CME)</option>
              <option value={9}>Other (specify)</option>
            </select>
          </div>
          <div className="edit-property">
            <label>Does your clinic have sufficient funds available to provide adequate maintenance/service to keep the requested equipment functioning properly for an extended period?</label>
            <input
              value={sufficientFunds}
              onChange={e => setSufficientFunds(e.target.value)}
            />
          </div>
          <div className="edit-property">
            <label>Is your institution able to provide aid in importing the donated items?</label>
            <input
              value={aidImportingDonatedItems}
              onChange={e => setAidImportingDonatedItems(e.target.value)}
            />
          </div>
        </div>
          
        <div className="edit-property">
          <label>Comments on human resources:</label>
          <AutoExpandingTextarea
            value={humanResourcesComments}
            onChange={e => setHumanResourcesComments(e.target.value)}
          />
        </div>
        
        <div className="edit-section">
          <div className="edit-property">
            <label>Language preference:</label>
            <input
              value={languagePreference}
              onChange={e => setLanguagePreference(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            disabled={loadingMutation}
            onClick={() => {
              addNotification('good', 'Save successful')
            }}
          >
            {loadingMutation ?
              <span>...Loading</span>
              :
              isEdit ? "Save":"Add"
            }
          </button>
        </div>
      </div>
      {/*<StyledChatContainer>
        <h3>Conversation</h3>
        {messages.map((msg, index) => (
          <Message key={index} {...msg} />
        ))}
        <ControlPanel>
          <StyledButton><FaExchangeAlt /> Transfer</StyledButton>
          <StyledButton><FaShare /> Share</StyledButton>
          <StyledButton><FaCog /> Options</StyledButton>
        </ControlPanel>
        <StyledInputBox>
          <StyledTextarea placeholder="Type a message..." />
          <StyledButton onClick={() => handleSendMessage("Sample Message")}><FaPaperPlane /></StyledButton>
        </StyledInputBox>
      </StyledChatContainer>*/}
      <Chat/>
    </>
  )
}
