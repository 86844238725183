import {gql, useQuery} from "@apollo/client";
import React, {useEffect, useState, useContext} from "react";
import {OptionsMenu, DateTimeInput, Button, NotificationContext, ResponsiveTableView} from '@solsys/solsys-reactjs';
import {FaPlus, FaCog} from 'react-icons/fa'

const gqlQuery = gql`
  query SourceOfFundingList($listRequest: source_of_funding_list_request) {
    source_of_funding(source_of_funding_list_request: $listRequest) {
      edges {
        id
        name
      }
      totalCount
    }
  }
`;

export const ListOptions = {
  sortBy: "sortBy",
  sortDirection: "sortDirection",
  startDate: "startDate",
  endDate: "endDate",
  name: "name",
}

export default function SourceOfFundingList({
  action,
  id: entityId,
  ...props
}) {
  const {addNotification} = useContext(NotificationContext)
  const isAdd = action === 'add'

  const [requestState, setRequestState] = props.state
  let {
    sortBy,sortDirection,
    startDate, endDate,
    name
  } = requestState
  const setListOptions = (state) => setRequestState({...requestState, ...state})
  const [optionSortBy, setOptionSortBy] = useState(sortBy)
  const [optionSortDirection, setOptionSortDirection] = useState(sortDirection)
  const [optionStartDate, setOptionStartDate] = useState(startDate)
  const [optionEndDate, setOptionEndDate] = useState(endDate)
  const applyOptions = () => {
    setListOptions({
      sortBy: optionSortBy,
      sortDirection: optionSortDirection,
      startDate: optionStartDate,
      endDate: optionEndDate,
    })
  }
  const resetOptions = () => {
    setOptionSortDirection("DESC")
    setOptionSortBy("production_id")
    setOptionStartDate(null)
    setOptionEndDate(null)
  }

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [limit, setLimit] = useState(100)

  const sortByOptions = [
    {
      _id: 1,
      text: "Name",
      sortBy: "name"
    },
  ]
  const sortDirectionOptions = [
    {
      _id: 1,
      text: "A to Z",
      sortDirection: "ASC"
    },
    {
      _id: 2,
      text: "Z to A",
      sortDirection: "DESC"
    },
  ]

  let variables = {
    listRequest: {
      filters: [],
      pagination: {limit},
      sorting: []
    }
  }
  if (sortBy && sortDirection) {
    let sortOption = sortByOptions.find(v => v.sortBy === sortBy)
    variables.listRequest.sorting.push({
      field: sortBy,
      direction: sortDirection,
      entity: sortOption?.sortEntity,
    })
  }
  if (startDate && endDate) {
    variables.listRequest.filters.push({
      field: "created",
      operator: "between",
      value: `${startDate.toFormat("MM/dd/yyyy hh:mm:ss")},${endDate.toFormat("MM/dd/yyyy hh:mm:ss")}`
    })
  } else if (startDate) {
    variables.listRequest.filters.push({
      field: "created",
      operator: ">",
      value: startDate.toFormat("MM/dd/yyyy hh:mm:ss")
    })
  } else if (endDate) {
    variables.listRequest.filters.push({
      field: "created",
      operator: "<",
      value: endDate.toFormat("MM/dd/yyyy hh:mm:ss")
    })
  }
  if (name) {
    variables.listRequest.filters.push({
      field: "name",
      operator: "like",
      value: `%${name}%`
    })
  }

  const [list, setList] = useState([])
  const { loading, data, refetch } = useQuery(gqlQuery, {
    variables,
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    onCompleted: localData => {
      console.log(localData?.source_of_funding?.edges)
      setList(localData?.source_of_funding?.edges ?? [])
    },
    onError: error => {
      console.error(error)
      addNotification("bad", "Something went wrong")
    }
  });
  useEffect(() => {
    if (props.onStateChange) props.onStateChange(requestState)
    refetch()
  }, [requestState, limit])
  const count = data?.source_of_funding?.totalCount ?? 0
  if (
    props.isMaster &&
    props.autoSelect &&
    !loading &&
    !isAdd &&
    !entityId
  ) {
    props.onSelect(list.length ? list[0].id : 0)
  }

  return (
    <OptionsMenu isOpen={isFilterMenuOpen}>
      <OptionsMenu.Menu>
        <div className="options-tools">
          <Button
            onClick={() => {
              applyOptions()
              setIsFilterMenuOpen(false)
            }}
            loading={loading}
          >Apply</Button>
          <button onClick={resetOptions}>
            Reset
          </button>
          <button onClick={() => setIsFilterMenuOpen(false)}>
            Close
          </button>
        </div>

        <div className="options-section">
          <h3>Filters</h3>

          <div className="option">
            <label>Start Date:</label>
            <DateTimeInput
              dateOnly
              value={optionStartDate}
              onChange={setOptionStartDate}
              timeZone='America/New_York'
            />
          </div>

          <div className="option">
            <label>End Date:</label>
            <DateTimeInput
              dateOnly
              value={optionEndDate}
              onChange={setOptionEndDate}
              timeZone='America/New_York'
            />
          </div>

        </div>

        <div className="options-section">
          <h3>Sorting</h3>

          <div className="option">
            <label>Sort By:</label>
            <div>
              <select
                value={optionSortBy}
                onChange={event => setOptionSortBy(event.target.value)}
              >
                {sortByOptions.map(option => (
                  <option key={option.text} value={option.sortBy}>{option.text}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="option">
            <label>Sort Order:</label>
            <div>
              <select
                value={optionSortDirection}
                onChange={event => setOptionSortDirection(event.target.value)}
              >
                {sortDirectionOptions.map(option => (
                  <option key={option.text} value={option.sortDirection}>{option.text}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="options-tools">
          <Button
            onClick={() => {
              applyOptions()
              setIsFilterMenuOpen(false)
            }}
            loading={loading}
          >Apply</Button>
          <button onClick={resetOptions}>
            Reset
          </button>
          <button onClick={() => setIsFilterMenuOpen(false)}>
            Close
          </button>
        </div>
      </OptionsMenu.Menu>
      <OptionsMenu.Layout>
        <div className="page-header">
          <span className="page-header-title">Sources</span>
          <div className="page-header-options">
            {props.isMaster &&
              <button onClick={() => props.onSelect(0)}>
                <FaPlus/>
              </button>
            }
            <button onClick={() => setIsFilterMenuOpen(true)}>
              <FaCog/>
            </button>
          </div>
        </div>
        <input value={name} style={{width: '100%'}} placeholder="search..." type='text' onChange={e => {
          setListOptions({name: e.target.value})
        }}/>
        <ResponsiveTableView
          style={{height: '100%'}}
          wrap
          loading={loading}
          headers={[{
            label: "Name",
          }]}
          data={list.map(entity => ({
            id: entity.id,
            selected: String(entity.id) === String(entityId) && !isAdd,
            Name: entity.name,
          }))}
          identityColumnKey="Name"
          onRowClick={(row) => {
            props.onSelect(row.id, true)
          }}
          totalCount={count}
          addMoreResults={() => {
            setLimit(limit + 100)
          }}
        />
      </OptionsMenu.Layout>
    </OptionsMenu>
  )
}