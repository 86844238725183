import {gql, useMutation, useQuery} from "@apollo/client";
import React, {useState} from "react";
import {withRouter} from 'react-router'
import {DateTime} from 'luxon'
import {LoadingView, Button, AutoExpandingTextarea} from "@solsys/solsys-reactjs";
import styled from 'styled-components';
import Chat from './Chat'

const entityQuery = gql`
  query Project($listRequest: project_list_request) {
    project(project_list_request: $listRequest) {
      edges {
        id
        created
        title
        website
        project2scope {
          edges {
            scope {
              name
            }
          }
        }
        beneficiary {
          name
        }
        beneficiary_coverage
        objective
        description
        professionals_involved {
          edges {
            profession {
              name
            }
          }
        }
        contact {
          edges {
            contact_name
          }
        }
        clinical_equipment2Project {
          edges {
            clinical_equipment {
              name
            }
          }
        }
        medical_physics_equipment2Project {
          edges {
            medical_physics_equipment {
              name
            }
          }
        }
        equipment_comments
        clinical_address1
        clinical_address2
        clinical_city
        clinical_country
        clinical_state
        clinical_zip
        project2research {
          edges {
            research {
              name
            }
          }
        }
        research_comments
        project2quality_assurance {
          edges {
            quality_assurance {
              name
            }
          }
        }
        resource2Project {
          edges {
            resource {
              name
            }
          }
        }
        maintenance_funding_comment
        donation_aid_comment
        hr_comment
        language_preference
      }
    }
  }
`

const entityMutation = gql`
  mutation projectMutate($project_mutation_request: MutationInput) {
    project(project_mutation_request: $project_mutation_request) {
      id
      isNew
    }
  }
`

// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   padding: 10px;
//   background-color: #f8f9fa;
//   box-shadow: 0 2px 3px rgba(0,0,0,0.1);
//   margin-bottom: 20px;
// `;

// const Label = styled.p`
//   font-size: 16px;
//   color: #6c757d;
//   margin: 0 10px;
// `;

// const Count = styled.p`
//   font-size: 24px;
//   color: #6c757d;
//   font-weight: bold;
//   margin: 0 10px 0 0;
// `;

// const CountItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: 10px;
// `;

export default withRouter(function({match}) {
  const entityId = match.params.id

  // variables for entity query const
  let variables = {
    listRequest: {
      filters: [
        {
          field: "id",
          operator: "=",
          value: String(entityId)
        }
      ]
    }
  }

  // fetches entity and sets up refetch
  const {
    data,
    loading: loadingEntity,
    refetch: refetchEntity,
  } = useQuery(entityQuery, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (localData) => {
      const listResponse = localData?.project
      const list = listResponse?.edges ?? []
      const project = list[0]
      // setName(project?.name)
      // setDescription(project?.description)
    },
    onError: error => {
      console.error(error)
    }
  });
  const project = (data?.project?.edges ?? [])[0]

  // sets up mutation
  const [mutateFunction, mutateInfo] = useMutation(entityMutation, {
    variables: {project_mutation_request: {
        id: entityId,
        properties: [
          // {key: "name", string: name},
          // {key: "description", string: description},
        ]
      }},
    onError: error => {
      console.error(error)
    },
    onCompleted: (response, originalRequestVariables) => {
      refetchEntity()
    }
  })

  let loadingMutation = mutateInfo.loading


  console.log(project)

  return loadingEntity ? <LoadingView/> : (<>
    <ProjectContainer key={project?.id}>
      <ProjectTitle>Project Title: {project?.title}</ProjectTitle>
      <DetailList>
        <PropWrap>
          <Label>Submitted:</Label>
          <DetailText>{DateTime.fromISO(project?.created).toFormat('DD')}</DetailText>
        </PropWrap>
        <PropWrap>
          <Label>Website:</Label>
          <DetailText>{project?.website}</DetailText>
        </PropWrap>
        <GroupWrap>
          <Label>Scope:</Label>
          {project?.project2scope.edges.map(scope => (
            <NestedItem key={scope.scope.name}>
              {scope.scope.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <PropWrap>
          <Label>Beneficiary:</Label>
          <DetailText>{project?.beneficiary.name}</DetailText>
        </PropWrap>
        <PropWrap>
          <Label>Beneficiary Coverage:</Label>
          <DetailText>{project?.beneficiary_coverage}</DetailText>
        </PropWrap>
        <PropWrap>
          <Label>Objective:</Label>
          <DetailText>{project?.objective}</DetailText>
        </PropWrap>
        <GroupWrap>
          <Label>Description:</Label>
          <AutoExpandingTextarea
            style={{marginTop: "8px"}}
            value={project?.description}
            disabled
          />
        </GroupWrap>
        <GroupWrap>
          <Label>Profession Involved:</Label>
          {project?.professionals_involved?.edges?.map(professional => (
            <NestedItem key={professional.profession.name}>
              {professional.profession.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <GroupWrap>
          <Label>Contacts:</Label>
          {project?.contact?.edges?.map(contact => (
            <NestedItem key={contact.contact_name}>
              {contact.contact_name}
            </NestedItem>
          ))}
        </GroupWrap>
        <GroupWrap>
          <Label>Clinical Equipment:</Label>
          {project?.clinical_equipment2Project?.edges?.map(equipment => (
            <NestedItem key={equipment.clinical_equipment.name}>
              {equipment.clinical_equipment.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <GroupWrap>
          <Label>Medical Physics Equipment:</Label>
          {project?.medical_physics_equipment2Project?.edges?.map(equipment => (
            <NestedItem key={equipment.medical_physics_equipment.name}>
              {equipment.medical_physics_equipment.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <PropWrap>
          <Label>Equipment Comments:</Label>
          <DetailText>{project?.equipment_comments}</DetailText>
        </PropWrap>
        <PropWrap>
          <Label>Address:</Label>
          <DetailText>{`${project?.clinical_address1}, ${project?.clinical_address2}, ${project?.clinical_city}, ${project?.clinical_country}, ${project?.clinical_state}, ${project?.clinical_zip}`}</DetailText>
        </PropWrap>
        <GroupWrap>
          <Label>Research:</Label>
          {project?.project2research?.edges?.map(research => (
            <NestedItem key={research.research.name}>
              {research.research.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <PropWrap>
          <Label>Research Comments:</Label>
          <DetailText>{project?.research_comments}</DetailText>
        </PropWrap>
        <GroupWrap>
          <Label>Quality Assurance:</Label>
          {project?.project2quality_assurance?.edges?.map(quality => (
            <NestedItem key={quality.quality_assurance.name}>
              {quality.quality_assurance.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <GroupWrap>
          <Label>Resource:</Label>
          {project?.resource2Project?.edges?.map(resource => (
            <NestedItem key={resource.resource.name}>
              {resource.resource.name}
            </NestedItem>
          ))}
        </GroupWrap>
        <GroupWrap>
          <Label>Maintenance Funding Comment:</Label>
          <AutoExpandingTextarea
            style={{marginTop: "8px"}}
            value={project?.maintenance_funding_comment}
            disabled
          />
        </GroupWrap>
        <GroupWrap>
          <Label>Donation Aid Comment:</Label>
          <AutoExpandingTextarea
            style={{marginTop: "8px"}}
            value={project?.donation_aid_comment}
            disabled
          />
        </GroupWrap>
        <GroupWrap>
          <Label>HR Comment:</Label>
          <AutoExpandingTextarea
            style={{marginTop: "8px"}}
            value={project?.hr_comment}
            disabled
          />
        </GroupWrap>
        <PropWrap>
          <Label>Language Preference:</Label>
          <DetailText>{project?.language_preference}</DetailText>
        </PropWrap>
      </DetailList>
    </ProjectContainer>
    <Chat project_id={Number(entityId)}/>
  </>)
})

const ProjectContainer = styled.div`
  padding: 20px;
  margin: 60px auto;
  max-width: 800px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const ProjectTitle = styled.h1`
  color: #2a2a2a;
  font-size: 24px;
`;

// const Section = styled.section`
//   margin-top: 20px;
// `;
//
// const SectionTitle = styled.h2`
//   font-size: 20px;
//   color: #444;
//   border-bottom: 1px solid #ccc;
//   padding-bottom: 5px;
// `;

const DetailList = styled.ul`
  list-style: none;
  padding: 0;
`;

const PropWrap = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
`;

const GroupWrap = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const Label = styled.span`
  font-weight: bold;
  color: #555;
`;

const DetailText = styled.span`
  color: #333;
`;

const NestedItem = styled.div`
  margin-top: 5px;
  padding-left: 20px;
  font-style: italic;
`;



