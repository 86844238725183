import React, {useState, useContext} from "react";
import {useMutation, gql} from '@apollo/client';
import styled, { keyframes, css } from 'styled-components';
import {
  DateTimeInput,
  LoadingView,
  NotificationContext,
  AutoExpandingTextarea,
  InvalidReasons
} from "@solsys/solsys-reactjs";
import ProfessionDropDown from "./ProfessionDropDown";
import BeneficiaryDropDown from "./BeneficiaryDropDown";
import SourceOfFundingDropDown from "./SourceOfFundingDropDown";
import ClinicalEquipmentDropDown from "./ClinicalEquipmentDropDown";
import MedicalPhysicsEquipmentDropDown from "./MedicalPhysicsEquipmentDropDown";
import ResearchDropDown from "./ResearchDropDown";
import QualityAssuranceDropDown from "./QualityAssuranceDropDown";
import ResourceDropDown from "./ResourceDropDown";
import ScopeDropDown from "./ScopeDropDown";
import StatusDropDown from "./StatusDropDown";

const serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

// Basic Styles and Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const neonGlow = keyframes`
  from { box-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 15px #0ff, 0 0 20px #00ffff, 0 0 25px #00ffff, 0 0 30px #00ffff, 0 0 35px #00ffff; }
  to { box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #00ffff, 0 0 50px #00ffff, 0 0 60px #00ffff, 0 0 70px #00ffff; }
`;

const baseButtonStyle = css`
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0ff;
  background: transparent;
  border: 1px solid #0ff;

  &:hover {
    animation: ${neonGlow} 1.5s alternate infinite;
  }

  svg {
    margin-right: 5px;
  }
`;

export default function ProjectSubmit({id: entityId, action, navigate}) {
  const isEdit = action === 'edit'
  
  const {addNotification} = useContext(NotificationContext)
  const errorHandler = (error) => {
    console.error(error);
    addNotification('bad', 'Something went wrong')
  }

  let [title, setTitle] = useState(null)
  let [scope, setScope] = useState(null)
  let [website, setWebsite] = useState(null)
  let [beneficiary, setBeneficiary] = useState(null)
  let [beneficiaryCoverage, setBeneficiaryCoverage] = useState(null)
  let [status, setStatus] = useState(null)
  let [startDate, setStartDate] = useState(null)
  let [endDate, setEndDate] = useState(null)
  let [objective, setObjective] = useState(null)
  let [description, setDescription] = useState(null)
  let [fundingSource, setFundingSource] = useState(null)
  let [professionals, setProfessionals] = useState(null)
  let [contacts, setContacts] = useState([])
  let [clinicalEquipment, setClinicalEquipment] = useState(null)
  let [medicalPhysicsEquipment, setMedicalPhysicsEquipment] = useState(null)
  let [equipmentComments, setEquipmentComments] = useState(null)
  let [clinicalAddress, setClinicalAddress] = useState({})
  let [research, setResearch] = useState(null)
  let [researchComments, setResearchComments] = useState(null)
  let [qualityAssurance, setQualityAssurance] = useState(null)
  let [humanResources, setHumanResources] = useState(null)
  let [sufficientFunds, setSufficientFunds] = useState(null)
  let [aidImportingDonatedItems, setAidImportingDonatedItems] = useState(null)
  let [humanResourcesComments, setHumanResourcesComments] = useState(null)
  let [languagePreference, setLanguagePreference] = useState(null)

  const invalidReasons = () => {
    let reasons = [];
    if (!title) reasons.push('A project title is required')
    if (!scope || scope.length===0) reasons.push('A scope must be selected')
    if (!beneficiary) reasons.push('A beneficiary must be selected')
    if (!website) reasons.push('A website is required');
    if (!beneficiaryCoverage) reasons.push('Beneficiary coverage is required');
    // if (!status) reasons.push('Status is required');
    // if (!startDate) reasons.push('Start date is required');
    // if (!endDate) reasons.push('End date is required');
    if (!objective) reasons.push('Objective is required');
    if (!description) reasons.push('Description is required');
    if (!fundingSource) reasons.push('Funding source is required');
    if (!professionals) reasons.push('Professionals involved are required');
    if (!contacts || contacts.length===0) reasons.push('At least one contact is required');
    if (!clinicalEquipment) reasons.push('Clinical equipment is required');
    if (!medicalPhysicsEquipment) reasons.push('Medical Physics equipment is required');
    // if (!equipmentComments) reasons.push('Equipment comments are required');
    if (!clinicalAddress || !clinicalAddress.address || !clinicalAddress.city || !clinicalAddress.country) reasons.push('Complete clinical address is required');
    if (!research) reasons.push('Research is required');
    // if (!researchComments) reasons.push('Research comments are required');
    if (!qualityAssurance) reasons.push('Quality Assurance is required');
    if (!humanResources) reasons.push('Human resources are required');
    if (!sufficientFunds) reasons.push('Information on sufficiency of funds is required');
    if (!aidImportingDonatedItems) reasons.push('Information on ability to aid in importing donated items is required');
    // if (!humanResourcesComments) reasons.push('Human resources comments are required');
    if (!languagePreference) reasons.push('Language preference is required');
    return reasons
  }

  const loadingEntity = false
  const [mutateProject, {loading: loadingProjectMutation}] = useMutation(gql`
    mutation projectMutate($request: MutationInput) {
      project(project_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateProject2Scope, {loading: loadingP2SMutation}] = useMutation(gql`
    mutation project2ScopeMutate($request: MutationInput) {
      project2scope(project2scope_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateProject2Research, {loading: loadingP2RMutation}] = useMutation(gql`
    mutation project2ResearchMutate($request: MutationInput) {
      project2research(project2research_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateProject2QualityAssurance, {loading: loadingP2QAMutation}] = useMutation(gql`
    mutation project2QualityAssuranceMutate($request: MutationInput) {
      project2quality_assurance(project2quality_assurance_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateResource2Project, {loading: loadingR2PMutation}] = useMutation(gql`
    mutation resource2ProjectMutate($request: MutationInput) {
      resource2Project(resource2Project_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateSourceOfFunding2Project, {loading: loadingSOF2PMutation}] = useMutation(gql`
    mutation sourceOfFunding2ProjectMutate($request: MutationInput) {
      source_of_funding2Project(source_of_funding2Project_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateMedicalPhysicsEquipment2Project, {loading: loadingMPE2PMutation}] = useMutation(gql`
    mutation medicalPhysicsEquipment2ProjectMutate($request: MutationInput) {
      medical_physics_equipment2Project(medical_physics_equipment2Project_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateClinicalEquipment2Project, {loading: loadingCE2PMutation}] = useMutation(gql`
    mutation clinicalEquipment2ProjectMutate($request: MutationInput) {
      clinical_equipment2Project(clinical_equipment2Project_mutation_request: $request) {
        id
        isNew
      }
    }
  `)
  const [mutateProfessionalsInvolved, {loading: loadingPIMutation}] = useMutation(gql`
    mutation professionalsInvolvedMutate($request: MutationInput) {
      professionals_involved(professionals_involved_mutation_request: $request) {
        id
        isNew
      }
    }
  `)

  const loadingMutation = loadingProjectMutation ||
    loadingP2SMutation ||
    loadingCE2PMutation ||
    loadingMPE2PMutation ||
    loadingPIMutation ||
    loadingP2QAMutation ||
    loadingR2PMutation ||
    loadingP2RMutation ||
    loadingSOF2PMutation
  
  return loadingEntity ? <LoadingView/> : (
    <>
      <div style={{height: "300px", background: "linear-gradient(175deg, rgb(42,58,152) 50%, white 50%)"}}></div>
      <div style={{display: "flex", justifyContent:"center", marginTop: "-100px", marginBottom:"30px"}}>
        <div>
          <img src='/aapm_Q42014.png'/>
          <img src='/banner_bg_Q42014.png'/>
        </div>
      </div>
      <div>


      </div>
      <div className="add-edit" style={{maxWidth: '900px', margin: "auto"}}>
        <div style={{
          boxShadow: 'inset 2px 2px 5px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.5)',
          borderRadius: '8px',
          padding: '16px'
        }}>
          <h2>AAPM Public Intake Form</h2>
          <p>The American Association of Physicists in Medicine is looking to consolidate all global health projects
            to a single source in an effort to increase the efficiency at which these projects are completed.</p>
          <p><i>Use the form below to provide information about your project, and we will work with you research, fund,
            and implement your goals.</i></p>
        </div>

        <div className="edit-section" style={{marginTop: "30px"}}>
          <div className="edit-property">
            <label>Title:</label>
            <input
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>

          <div className="edit-property">
            <label>Website:</label>
            <input
              value={website}
              onChange={e => setWebsite(e.target.value)}
            />
          </div>

        </div>
        <div className="edit-section">

          <div className="edit-property">
            <label>Scope:</label>
            <ScopeDropDown
              selected={scope}
              onChange={setScope}
              multiple
              minWidth={400}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={scope}*/}
            {/*  onChange={e => setScope(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Education & Training</option>*/}
            {/*  <option value={2}>Planning/Setting up a new radiation therapy/radiology center or service line</option>*/}
            {/*  <option value={3}>Upgrading an existing radiation therapy/radiology center or service line</option>*/}
            {/*  <option value={4}>Clinical program development/improvement</option>*/}
            {/*  <option value={5}>Clinical support through visiting faculty</option>*/}
            {/*  <option value={6}>Clinical support through full time faculty on the ground</option>*/}
            {/*  <option value={7}>Medical physics, clinician, and technologist programs</option>*/}
            {/*  <option value={8}>Supporting Ministry of Health in developing radiation program</option>*/}
            {/*  <option value={9}>Research & Development</option>*/}
            {/*  <option value={10}>Equipment transfer/ donation</option>*/}
            {/*  <option value={11}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

        </div>
        <div className="edit-section">

          <div className="edit-property">
            <label>Beneficiary:</label>
            <BeneficiaryDropDown
              selected={beneficiary}
              onChange={setBeneficiary}
              minWidth={500}
              truncateLength={80}
            />
            {/*<select*/}
            {/*  value={beneficiary}*/}
            {/*  onChange={e => setBeneficiary(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Global</option>*/}
            {/*  <option value={2}>Single Institution</option>*/}
            {/*  <option value={3}>Upgrading an existing radiation therapy/radiology center or service line</option>*/}
            {/*  <option value={4}>National (country)</option>*/}
            {/*  <option value={5}>Regional</option>*/}
            {/*  <option value={6}>Please specify project beneficiary including Name of Institution(s), City, Country*/}
            {/*  </option>*/}
            {/*</select>*/}
          </div>

        </div>
        <div className="edit-section">

          <div className="edit-property">
            <label>Beneficiary coverage:</label>
            <input
              value={beneficiaryCoverage}
              onChange={e => setBeneficiaryCoverage(e.target.value)}
            />
          </div>

        </div>
        {/*<div className="edit-section">*/}

        {/*  <div className="edit-property">*/}
        {/*    <label>Status:</label>*/}
        {/*    <StatusDropDown*/}
        {/*      selected={status}*/}
        {/*      onChange={setStatus}*/}
        {/*    />*/}
        {/*    /!*<select*!/*/}
        {/*    /!*  value={status}*!/*/}
        {/*    /!*  onChange={e => setStatus(e.target.value)}*!/*/}
        {/*    /!*>*!/*/}
        {/*    /!*  <option/>*!/*/}
        {/*    /!*  <option value={1}>Ongoing</option>*!/*/}
        {/*    /!*  <option value={2}>Closed</option>*!/*/}
        {/*    /!*  <option value={3}>On-Hold</option>*!/*/}
        {/*    /!*  <option value={4}>In Planning</option>*!/*/}
        {/*    /!*  <option value={5}>Other</option>*!/*/}
        {/*    /!*</select>*!/*/}
        {/*  </div>*/}

        {/*</div>*/}
        {/*<div className="edit-section">*/}

        {/*  <div className="edit-property">*/}
        {/*    <label>Start Date:</label>*/}
        {/*    <DateTimeInput*/}
        {/*      dateOnly*/}
        {/*      timeZone='America/New_York'*/}
        {/*      value={startDate}*/}
        {/*      onChange={date => {*/}
        {/*        setStartDate(date)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className="edit-property">*/}
        {/*    <label>End Date:</label>*/}
        {/*    <DateTimeInput*/}
        {/*      dateOnly*/}
        {/*      timeZone='America/New_York'*/}
        {/*      value={endDate}*/}
        {/*      onChange={date => {*/}
        {/*        setEndDate(date)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*</div>*/}

        <div className="edit-property">
          <label>Objective:</label>
          <input
            style={{width: "100%"}}
            value={objective}
            onChange={e => setObjective(e.target.value)}
          />
        </div>

        <div className="edit-property">
          <label>Description:</label>
          <AutoExpandingTextarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>

        <div className="edit-section">

          <div className="edit-property">
            <label>Sources of funding:</label>
            <SourceOfFundingDropDown
              multiple
              selected={fundingSource}
              onChange={setFundingSource}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={fundingSource}*/}
            {/*  onChange={e => setFundingSource(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Department</option>*/}
            {/*  <option value={2}>Institution</option>*/}
            {/*  <option value={3}>Grant</option>*/}
            {/*  <option value={4}>Fundraising</option>*/}
            {/*  <option value={5}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

          <div className="edit-property">
            <label>Professionals involved:</label>
            <ProfessionDropDown
              multiple
              searchEnabled
              selected={professionals}
              onChange={setProfessionals}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={professionals}*/}
            {/*  onChange={e => setProfessionals(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Radiation oncologist</option>*/}
            {/*  <option value={2}>Medical physicist</option>*/}
            {/*  <option value={3}>Therapy radiographers</option>*/}
            {/*  <option value={4}>Radiation biologist</option>*/}
            {/*  <option value={5}>Student</option>*/}
            {/*  <option value={6}>Medical oncologist</option>*/}
            {/*  <option value={7}>Surgical oncologist</option>*/}
            {/*  <option value={8}>Radiologist</option>*/}
            {/*  <option value={9}>RT tech</option>*/}
            {/*  <option value={10}>Radiology tech</option>*/}
            {/*  <option value={11}>DIT specialist</option>*/}
            {/*  <option value={12}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

        </div>

        <h4>Contact Info</h4>

        {contacts.map((contact, index) => (
          <React.Fragment key={index}>
            {index > 0 &&
              <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>
            }
            <div className="edit-section">
              <div className="edit-property">
                <label>Name:</label>
                <input
                  value={contact.name}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].name = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Email:</label>
                <input
                  value={contact.email}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].email = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
            </div>
            <div className="edit-section">
              <div className="edit-property">
                <label>Institution Name:</label>
                <input
                  value={contact.institutionName}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionName = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Address:</label>
                <input
                  value={contact.institutionAddress}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionAddress = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>City:</label>
                <input
                  value={contact.institutionCity}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionCity = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <div className="edit-property">
                <label>Country:</label>
                <input
                  value={contact.institutionCountry}
                  onChange={e => {
                    let newContacts = [...contacts]
                    newContacts[index].institutionCountry = e.target.value
                    setContacts(newContacts)
                  }}
                />
              </div>
              <label>Other preferred contact method:</label>
              <AutoExpandingTextarea
                value={contact.otherContact}
                onChange={e => {
                  let newContacts = [...contacts]
                  newContacts[index].otherContact = e.target.value
                  setContacts(newContacts)
                }}
              />
            </div>
          </React.Fragment>
        ))}

        <button onClick={() => {
          let newContacts = [...contacts]
          newContacts.push({name: ""})
          setContacts(newContacts)
        }}>Add Contact
        </button>

        <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>

        <div className="edit-section">

          <div className="edit-property">
            <label>Clinical equipment:</label>
            <ClinicalEquipmentDropDown
              multiple
              selected={clinicalEquipment}
              onChange={setClinicalEquipment}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={clinicalEquipment}*/}
            {/*  onChange={e => setClinicalEquipment(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>External Beam Therapy</option>*/}
            {/*  <option value={2}>Brachytherapy</option>*/}
            {/*  <option value={3}>CT-simulator</option>*/}
            {/*  <option value={4}>Conventional simulator</option>*/}
            {/*  <option value={5}>Treatment planning system TPS</option>*/}
            {/*  <option value={6}>Planar-only gamma cameras</option>*/}
            {/*  <option value={7}>SPECT gamma cameras</option>*/}
            {/*  <option value={8}>SPECT-CT gamma cameras</option>*/}
            {/*  <option value={9}>Cyclotron</option>*/}
            {/*  <option value={10}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

        </div>
        <div className="edit-section">

          <div className="edit-property">
            <label>Medical physics equipment:</label>
            <MedicalPhysicsEquipmentDropDown
              multiple
              selected={medicalPhysicsEquipment}
              onChange={setMedicalPhysicsEquipment}
              minWidth={400}
              truncateLength={60}
            />
            {/*<select*/}
            {/*  value={medicalPhysicsEquipment}*/}
            {/*  onChange={e => setMedicalPhysicsEquipment(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Ion Chamber</option>*/}
            {/*  <option value={2}>Barometer</option>*/}
            {/*  <option value={3}>Breast board</option>*/}
            {/*  <option value={4}>Densitometer</option>*/}
            {/*  <option value={5}>Electrometer</option>*/}
            {/*  <option value={6}>Extension cable</option>*/}
            {/*  <option value={7}>External beam chamber</option>*/}
            {/*  <option value={8}>Imaging chamber</option>*/}
            {/*  <option value={9}>Imaging test tool</option>*/}
            {/*  <option value={10}>kVp meter</option>*/}
            {/*  <option value={11}>Oscilloscope</option>*/}
            {/*  <option value={12}>Phantoms</option>*/}
            {/*  <option value={13}>Scanning system</option>*/}
            {/*  <option value={14}>Shielding</option>*/}
            {/*  <option value={15}>Survey meter (GM counter)</option>*/}
            {/*  <option value={16}>Thermometer</option>*/}
            {/*  <option value={17}>Timer</option>*/}
            {/*  <option value={18}>Water tank</option>*/}
            {/*  <option value={19}>Well-Ionization chamber</option>*/}
            {/*  <option value={20}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

        </div>

        <div className="edit-property">
          <label>Equipment Comments:</label>
          <AutoExpandingTextarea
            value={equipmentComments}
            onChange={e => setEquipmentComments(e.target.value)}
          />
        </div>


        <h4>Clinical address (if different from the institution)</h4>
        <div className="edit-section">
          <div className="edit-property">
            <label>Address</label>
            <input
              value={clinicalAddress.address}
              onChange={e => setClinicalAddress({...clinicalAddress, address: e.target.value})}
            />
          </div>
        </div>
        <div className="edit-section">
          <div className="edit-property">
            <label>City</label>
            <input
              value={clinicalAddress.city}
              onChange={e => setClinicalAddress({...clinicalAddress, city: e.target.value})}
            />
          </div>
        </div>
        <div className="edit-section">
          <div className="edit-property">
            <label>Country</label>
            <input
              value={clinicalAddress.country}
              onChange={e => setClinicalAddress({...clinicalAddress, country: e.target.value})}
            />
          </div>
        </div>

        <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>

        <div className="edit-section">
          <div className="edit-property">
            <label>Research:</label>
            <ResearchDropDown
              multiple
              selected={research}
              onChange={setResearch}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={research}*/}
            {/*  onChange={e => setResearch(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Clinical trials</option>*/}
            {/*  <option value={2}>Patterns of care studies</option>*/}
            {/*  <option value={3}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>

        </div>

        <div className="edit-property">
          <label>Comments on research:</label>
          <AutoExpandingTextarea
            value={researchComments}
            onChange={e => setResearchComments(e.target.value)}
          />
        </div>

        <div className="edit-section">
          <div className="edit-property">
            <label>Quality Assurance:</label>
            <QualityAssuranceDropDown
              multiple
              selected={qualityAssurance}
              onChange={setQualityAssurance}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={qualityAssurance}*/}
            {/*  onChange={e => setQualityAssurance(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Remote Services, e.g. Reporting, treatment planning</option>*/}
            {/*  <option value={2}>Peer review</option>*/}
            {/*  <option value={3}>Commissioning</option>*/}
            {/*  <option value={4}>Planning</option>*/}
            {/*  <option value={5}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>
        </div>

        <h4>Human Resources</h4>
        <div className="edit-section">
          <div className="edit-property">
            <label>Resources:</label>
            <ResourceDropDown
              multiple
              selected={humanResources}
              onChange={setHumanResources}
              minWidth={300}
              truncateLength={50}
            />
            {/*<select*/}
            {/*  value={humanResources}*/}
            {/*  onChange={e => setHumanResources(e.target.value)}*/}
            {/*>*/}
            {/*  <option/>*/}
            {/*  <option value={1}>Education resources</option>*/}
            {/*  <option value={2}>Clinical procedures</option>*/}
            {/*  <option value={3}>Long-term training</option>*/}
            {/*  <option value={4}>Short-term training</option>*/}
            {/*  <option value={5}>On-site support</option>*/}
            {/*  <option value={6}>Telemedicine</option>*/}
            {/*  <option value={7}>Continuing Medical</option>*/}
            {/*  <option value={8}>Education (CME)</option>*/}
            {/*  <option value={9}>Other (specify)</option>*/}
            {/*</select>*/}
          </div>
          <div className="edit-property">
            <label>Does your clinic have sufficient funds available to provide adequate maintenance/service to keep the
              requested equipment functioning properly for an extended period?</label>
            <input
              value={sufficientFunds}
              onChange={e => setSufficientFunds(e.target.value)}
            />
          </div>
          <div className="edit-property">
            <label>Is your institution able to provide aid in importing the donated items?</label>
            <input
              value={aidImportingDonatedItems}
              onChange={e => setAidImportingDonatedItems(e.target.value)}
            />
          </div>
        </div>

        <div className="edit-property">
          <label>Comments on human resources:</label>
          <AutoExpandingTextarea
            value={humanResourcesComments}
            onChange={e => setHumanResourcesComments(e.target.value)}
          />
        </div>

        <div className="edit-section">
          <div className="edit-property">
            <label>Language preference:</label>
            <input
              value={languagePreference}
              onChange={e => setLanguagePreference(e.target.value)}
            />
          </div>
        </div>


        <hr style={{margin: '30px 100px', borderTop: "1px #ddd solid"}}/>

        <div style={{
          boxShadow: 'inset 2px 2px 5px rgba(0, 0, 0, 0.2), inset -2px -2px 5px rgba(255, 255, 255, 0.5)',
          borderRadius: '8px',
          padding: '16px'
        }}>
          <p><i>Once you submit this form, you will receive a confirmation email with a link to track your project. Be sure
            to watch out for updates!</i></p>
        </div>

        <div>
          <button
            disabled={loadingMutation || invalidReasons().length}
            onClick={() => {
              return mutateProject({variables: {request: {properties: [
                {key: "title", string: title},
                {key: "website", string: website},
                {key: "objective", string: objective},
                {key: "description", string: description},
                {key: "equipment_comments", string: equipmentComments},
                {key: "research_comments", string: researchComments},
                {key: "clinical_address1", string: clinicalAddress.address},
                {key: "clinical_city", string: clinicalAddress.city},
                {key: "clinical_state", string: clinicalAddress.state},
                {key: "clinical_zip", string: clinicalAddress.zip},
                {key: "clinical_country", string: clinicalAddress.country},
                {key: "beneficiary_id", number: beneficiary},
                {key: "status_id", number: 1},
              ]}}})
              .then(response => {
                let project_id = response?.data?.project?.id
                let promises = Promise.resolve()
                scope.forEach(scope_id => {
                  promises = promises.then(() => {
                    return mutateProject2Scope({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "scope_id", number: scope_id}
                    ]}}})
                  })
                })
                clinicalEquipment.forEach(clinical_equipment_id => {
                  promises = promises.then(() => {
                    return mutateClinicalEquipment2Project({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "clinical_equipment_id", number: clinical_equipment_id}
                    ]}}})
                  })
                })
                medicalPhysicsEquipment.forEach(medical_physics_equipment_id => {
                  promises = promises.then(() => {
                    return mutateMedicalPhysicsEquipment2Project({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "medical_physics_equipment_id", number: medical_physics_equipment_id}
                    ]}}})
                  })
                })
                professionals.forEach(profession_id => {
                  promises = promises.then(() => {
                    return mutateProfessionalsInvolved({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "profession_id", number: profession_id}
                    ]}}})
                  })
                })
                qualityAssurance.forEach(quality_assurance_id => {
                  promises = promises.then(() => {
                    return mutateProject2QualityAssurance({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "quality_assurance_id", number: quality_assurance_id}
                    ]}}})
                  })
                })
                research.forEach(research_id => {
                  promises = promises.then(() => {
                    return mutateProject2Research({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "research_id", number: research_id}
                    ]}}})
                  })
                })
                humanResources.forEach(resource_id => {
                  promises = promises.then(() => {
                    return mutateResource2Project({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "resource_id", number: resource_id}
                    ]}}})
                  })
                })
                fundingSource.forEach(source_of_funding_id => {
                  promises = promises.then(() => {
                    return mutateSourceOfFunding2Project({variables: {request: {properties: [
                      {key: "project_id", number: Number(project_id)},
                      {key: "source_of_funding_id", number: source_of_funding_id}
                    ]}}})
                  })
                })
                return promises
              })
              .then(() => {
                addNotification('good', 'Save successful')
              })
              .catch(error => {
                console.error(error)
                addNotification('bad', 'Something went wrong')
              })
            }}
          >
            {loadingMutation ?
              <span>...Loading</span>
              :
              isEdit ? "Save" : "Submit"
            }
          </button>
        </div>
        {!!invalidReasons().length &&
          <InvalidReasons reasons={invalidReasons()} />
        }
      </div>
    </>
  )
}


/*

Education & Training
Planning/Setting up a new radiation therapy/radiology center or service line
Upgrading an existing radiation therapy/radiology center or service line
Clinical program development/improvement
Clinical support through visiting faculty
Clinical support through full time faculty on the ground
"Medical physics, clinician, and technologist programs"
Supporting Ministry of Health in developing radiation program
Research & Development
Equipment transfer/ donation


New
On-Going
Closed
On-Hold
In-Planning
Other


 */