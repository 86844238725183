import React, { useState, useEffect, useRef } from "react";
import {FaChevronLeft, FaChevronRight, FaAngleDown} from "react-icons/fa"
import styled from 'styled-components';
import {LoadingView} from "@solsys/solsys-reactjs"

const DropDownContainer = styled.div`
  position: relative;
  min-width: ${props => props.$minWidth}px;
  font-family: Arial, sans-serif;
  color: black;

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: #daecf4;
    border: 1px solid #7bd2f6;
    cursor: ${props => props.$disabled ? "not-allowed":"pointer"};
  }
  .dropdown-header:hover {
    background-color: #c3d4db;
    border-color: #9199a1;
  }
  .dropdown-icon {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
  .dropdown-value, .dropdown-no-value {
    line-height: 1.15;
  }
  .dropdown-value.k-i-loading {
    line-height: 22px!important;
    height: 22px;
    padding: 3px 0;
  }
  .dropdown-no-value {
    color: #8e8f91;
  }

  .dropdown-list {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .dropdown-search-container {
    width: 100%;
  }

  .dropdown-search {
    width: 100%!important;
    padding: 3px 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    line-height: 22px;
  }
  .dropdown-item-container {
    max-height: 200px;
    overflow-y: scroll;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    color: #003f59;
  }

  .dropdown-item:hover {
    background-color: #7bd2f6;
  }

  .dropdown-item.selected, .dropdown-item.selected .sub-text {
    background-color: #116081;
    color: #fff;
  }

  .item-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start!important;
    margin-left: 10px;
    text-align: left;
  }

  .main-text {
    font-size: 14px;
  }

  .sub-text {
    font-size: 12px;
    color: #666;
  }

  .dropdown-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    background-color: #f1f1f1;
    border-top: 1px solid #ccc;
  }

  .clear-button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    outline: none;
  }

  .totalCount {
    font-size: 12px;
    color: #5E6D78;
  }
  .pagination {
    display: flex;
    align-items: center;
  }

  .pagination button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    outline: none;
    margin: 0 3px;
    padding: 4px 6px;
  }
  .pagination button[disabled] {
    cursor: default!important;
    opacity: .6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
  }
  .pagination button:hover {
    color: #000;
    background-color: #e6e6e6;
  }

  .pagination button .k-icon {
    font-size: 18px;
  }

  .pagination span {
    font-size: 12px;
  }
`

function renderText(value, item) {
  if (typeof value === 'function') {
    return <span className="main-text">{value(item)}</span>
  } else if (typeof value === 'string' && item[value]) {
    return <span className="main-text">{item[value]}</span>
  } else return null
}

function renderSubtext(value, item) {
  if (typeof value === 'function') {
    return <span className="sub-text">{value(item)}</span>
  } else if (typeof value === 'string' && item[value]) {
    return <span className="sub-text">{item[value]}</span>
  } else return null
}

let enterHitOnLoading = false

const DropDown = ({
  items,
  loading,
  renderLoading,
  multiple,
  searchEnabled,
  searchValue,
  onSearch,
  selectValue,
  selectRender,
  onSelect,
  dataKey,
  textKey, // deprecated in favor of text which can be a function or string
  text,
  subtextKey, // deprecated in favor of subtext which can be a function or string
  subtext,
  truncateLength,
  paginationInfo,
  entityCount,
  onPaginationChange,
  disabled = false,
  minWidth = 200,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      searchRef.current?.focus();
    }
  }, [isOpen])

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  const handleSelect = (value) => {
    let id = value[dataKey]
    if(!multiple) {
      onSelect(id, value)
      setIsOpen(false)
    } else {
      if(selectValue && selectValue.includes(id)) {
        onSelect(selectValue.filter(v => v !== id))
      } else onSelect([...(selectValue ?? []), id])
    }
  };

  if (enterHitOnLoading && items.length) {
    enterHitOnLoading = false
    handleSelect(items[0])
  }

  const handleClear = () => onSelect(null);

  const handlePagination = (newPage) => {
    onPaginationChange(newPage);
  };

  const { currentPage, totalPages } = paginationInfo;
  
  const renderValue = () => {
    let value;
    if(renderLoading) return <LoadingView size={18} noPadding/>
    if((selectValue && selectValue.length === 0) || !selectValue) {
      return <span className="dropdown-no-value">Select an item</span>
    } else {
      if(!multiple) {
        value = selectRender?.[0]?.[textKey]
      }
      else {
        let array = [];
        selectRender.forEach(i => {
          array.push(i?.[textKey])
        })
        value = array.join(", ")
      }
    }
    if (value?.length > truncateLength) value = value.substring(0, truncateLength - 1) + "...";
    return <span className="dropdown-value">{value}</span>
  }
  
  const renderItemClass = (item) => {
    let className = "dropdown-item"
    if(!multiple) {
      if(selectValue === item[dataKey]) className += " selected"
    }
    return className
  }

  return (
    <DropDownContainer className="dropdown" ref={wrapperRef} $disabled={disabled} $minWidth={minWidth}>
      <div className="dropdown-header" onClick={disabled ? undefined : toggle}>
        {renderValue()}
        {!disabled &&
          <div className="dropdown-icon">
            <FaAngleDown/>
          </div>
        }
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {searchEnabled &&
            <div className="dropdown-search-container">
              <input
                className="dropdown-search"
                type="text"
                value={searchValue}
                onChange={onSearch}
                placeholder="Search"
                ref={searchRef}
                onKeyDown={e => {
                  if (e.key === 'Enter' && loading) {
                    enterHitOnLoading = true
                  } else if (e.key === 'Enter' && items.length === 1) {
                    handleSelect(items[0]);
                  }
                }}
              />
            </div>
          }
          
          {loading ? <LoadingView size={18}/> :
            <div className="dropdown-item-container">
              {items.length > 0 && items.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleSelect(item)}
                  className={renderItemClass(item)}
                >

                  {multiple && (
                    <input
                      type="checkbox"
                      checked={selectValue && selectValue.includes(item[dataKey])}
                      readOnly
                    />
                  )}
                  <div className="item-text">
                    {renderText(text ?? textKey, item)}
                    {renderSubtext(subtext ?? subtextKey, item)}
                  </div>
                </div>
              ))}
            </div>
          }
          <div className="dropdown-footer">
  
            <button
              className="negativeButton customButton"
              disabled={selectValue?.length === 0}
              onClick={handleClear}
            >
              Clear
            </button>
  
            {entityCount <= items.length ? null :
              <div className="pagination">
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePagination(currentPage - 1)}
                >
                  <FaChevronLeft/>
                </button>
                <span style={{fontSize: "14px"}}>
                  {currentPage} / {Math.ceil(totalPages)}
                </span>
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePagination(currentPage + 1)}
                >
                  <FaChevronRight/>
                </button>
              </div>
            }
            
            <span className="totalCount">{`(${entityCount.toLocaleString()})`}</span>
          </div>
        </div>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
