import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {SolsysAuthReact} from '@solsys/solsys-reactjs';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";
const clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";
const appVersion = process.env.REACT_APP_VERSION || "RUNTIME_REPLACE_REACT_APP_VERSION";

const link = createUploadLink({
  uri: serverUrl + '/graphql',
  credentials: 'include',
  headers: {
    clientId: clientId,
    version: appVersion,
  }
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SolsysAuthReact>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </SolsysAuthReact>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
