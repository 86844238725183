import React, {useState, useContext} from 'react';
import {Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';
import {AppNavigation, NavigationMenu, RouteStack, Welcome, NotificationManager, BasicHeader, SolsysAuthReactContext} from '@solsys/solsys-reactjs'
import {FaHome, FaFile, FaMap, FaFolder, FaUser, FaMoneyBillWave, FaPeopleCarry, FaNotesMedical, FaLaptopMedical, FaClipboard, FaClipboardCheck, FaPeopleArrows} from "react-icons/fa"

import "@solsys/solsys-reactjs/dist/assets/css/Global.css";
import "./Styles/App.css";

// import Header from './Components/Header'
import ProjectController from './Components/ProjectController'
import ProfessionController from './Components/ProfessionController'
import BeneficiaryController from './Components/BeneficiaryController'
import SourceOfFundingController from './Components/SourceOfFundingController'
import ClinicalEquipmentController from './Components/ClinicalEquipmentController'
import MedicalPhysicsEquipmentController from './Components/MedicalPhysicsEquipmentController'
import ResearchController from './Components/ResearchController'
import QualityAssuranceController from './Components/QualityAssuranceController'
import ResourceController from './Components/ResourceController'
import ImpactView from "./Components/ImpactView";
import ProjectSubmit from "./Components/ProjectSubmit";
import ProjectManagement from "./Components/ProjectManagement"
import ProjectView from "./Components/ProjectView"

function App({history}) {
  const {logout} = useContext(SolsysAuthReactContext)
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  return (
    <NotificationManager>
      <AppNavigation menuOpen={menuOpen} toggleMenu={toggleMenu}>
        <AppNavigation.Header pixelHeight={40}>
          <BasicHeader
            title="AAPM Project Management"
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
          >
            <div style={{flexGrow: 1}}>
              <span style={{float: 'right', cursor: 'pointer'}} onClick={() => logout()}>Logout</span>
            </div>
          </BasicHeader>
        </AppNavigation.Header>
        <AppNavigation.MainMenu
          pixelWidth={300}
          transition='transform 0.3s ease-in-out'
          style={{
            background: "white",
            zIndex: 1100,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "7px 6px"
            }}
          >
            <h4 style={{margin: "0"}}>Main Menu</h4>
            <div><button onClick={() => toggleMenu()} style={{
              fontWeight: "bold",
              padding: "4px 6px"}}>Close</button></div>
          </div>
          <NavigationMenu
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
            items={[
              {
                name: "Welcome",
                icon: <FaHome />,
                onClick: () => { history.push('/welcome'); toggleMenu() },
                selected: false
              },
              {
                name: "Projects",
                icon: <FaFile />,
                onClick: () => { history.push('/manage'); toggleMenu() },
                selected: false
              },
              {
                name: "Impact",
                icon: <FaMap />,
                onClick: () => { history.push('/impact'); toggleMenu() },
                selected: false
              },
              {
                name: "Intake Form",
                icon: <FaFolder />,
                onClick: () => { history.push('/submit'); toggleMenu() },
                selected: false
              },
              {
                name: "Beneficiaries",
                icon: <FaPeopleCarry />,
                onClick: () => { history.push('/beneficiary/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Sources of Funding",
                icon: <FaMoneyBillWave />,
                onClick: () => { history.push('/source-of-funding/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Professions",
                icon: <FaUser />,
                onClick: () => { history.push('/profession/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Clinical Equipment",
                icon: <FaNotesMedical />,
                onClick: () => { history.push('/clinical-equipment/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Medical Physics Equipment",
                icon: <FaLaptopMedical />,
                onClick: () => { history.push('/medical-physics-equipment/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Research",
                icon: <FaClipboard />,
                onClick: () => { history.push('/research/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Quality Assurance",
                icon: <FaClipboardCheck />,
                onClick: () => { history.push('/quality-assurance/list'); toggleMenu() },
                selected: false
              },
              {
                name: "Resource",
                icon: <FaPeopleArrows />,
                onClick: () => { history.push('/resource/list'); toggleMenu() },
                selected: false
              },
            ]}
          />
        </AppNavigation.MainMenu>
        <AppNavigation.MainContent>
          <Switch>
            <Route exact path='/' render={props => {props.history.replace('/welcome')}}/>
            <Route path='/welcome' render={() =>
              <Welcome
                message="In early development, seeing this message can already be a good thing! If you're feeling adventurous, visit the main menu. Later stages will have a more comprehensive user experience!"
              />
            }/>
            <RouteStack path='/projects'>
              {props => <ProjectController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/profession'>
              {props => <ProfessionController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/beneficiary'>
              {props => <BeneficiaryController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/source-of-funding'>
              {props => <SourceOfFundingController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/clinical-equipment'>
              {props => <ClinicalEquipmentController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/medical-physics-equipment'>
              {props => <MedicalPhysicsEquipmentController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/research'>
              {props => <ResearchController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/quality-assurance'>
              {props => <QualityAssuranceController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <RouteStack path='/resource'>
              {props => <ResourceController
                {...props}
                style={{height: "calc(100vh - 40px)"}}
              />}
            </RouteStack>
            <Route path='/impact' component={ImpactView}/>
            <Route path='/submit' component={ProjectSubmit}/>
            <Route path='/manage' component={ProjectManagement}/>
            <Route path='/project/:id' component={ProjectView}/>
          </Switch>
        </AppNavigation.MainContent>
      </AppNavigation>
    </NotificationManager>
  );
}

export default withRouter(App);
