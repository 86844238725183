import React, {useEffect, useRef, useState} from "react";
import ProjectList , {ListOptions} from "./ProjectList";
import ProjectEdit from "./ProjectEdit";
import {MDSplitView, ResponsiveLayout, LoadingView} from "@solsys/solsys-reactjs";
import {DateTime} from "luxon";

export default function ProjectController({navigate, action, id, search, style, options = {}}) {
  let listState = {
    [ListOptions.page]: 1,
    [ListOptions.pageSize]: 20,
    [ListOptions.sortBy]: 'CREATEDAT_',
    [ListOptions.sortDirection]: "DESC",
  }
  const didMount = useRef(false)
  useEffect(() => didMount.current ? undefined:(didMount.current = true))
  if (!didMount.current && Object.keys(search).length) {
    Object.keys(ListOptions).forEach(key => {
      listState[key] = search[key]
    })
  }

  listState = useState(listState)
  
  return (
    <div className='project'>
      <MDSplitView
        globalName='ProjectMDV'
        style={style}
        action={action}
        master={({isNarrow, autoSelect}) => <ProjectList
          isMaster
          state={listState}
          autoSelect={autoSelect}
          id={id}
          action={action}
          onStateChange={state => {
            navigate({
              action,
              id,
              search: state,
              push: false,
            })
          }}
          onSelect={newId => {
            navigate({
              popOut: isNarrow,
              ...newId === 0 ? {
                action: 'add'
              } : {
                action: 'edit',
                id: newId
              },
              search: listState[0]
            })
          }}
        />}
        detail={() =>
          <ProjectEdit
            key={`action_${action}_id_${id}`}
            id={id}
            action={action}
            navigate={navigate}
            item={options.item}
            supplier={options.supplier}
          />
        }
        initialWidth={320}
        maxWidth={350}
        minWidth={280}
        openInitially={true}
      />
    </div>
  )
}
