import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {DateTime} from 'luxon'
import {OptionsMenu, ListView, Pagination, DateTimeInput, LoadingView} from '@solsys/solsys-reactjs';

export const ListOptions = {
  page: "page",
  pageSize: "pageSize",
  sortBy: "sortBy",
  sortDirection: "sortDirection",
  startDate: "startDate",
  endDate: "endDate",
}

export default function ProjectList({
  id: entityId, navigate, action, ...props
}) {
  const isAdd = action === 'add'

  // request state
  const [requestState, setRequestState] = props.state

  let {
    page,pageSize,
    sortBy,sortDirection,
    startDate, endDate
  } = requestState
  const setListOptions = (state) => setRequestState({...requestState, ...state})

  // local state
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [optionSortBy, setOptionSortBy] = useState(sortBy)
  const [optionSortDirection, setOptionSortDirection] = useState(sortDirection)
  const [optionStartDate, setOptionStartDate] = useState(startDate)
  const [optionEndDate, setOptionEndDate] = useState(endDate)

  const sortByOptions = [
    {
      _id: 1,
      text: "Created",
      sortBy: "CREATEDAT_"
    }
  ]
  const sortDirectionOptions = [
    {
      _id: 1,
      text: "Ascend",
      sortDirection: "ASC"
    },
    {
      _id: 2,
      text: "Descend",
      sortDirection: "DESC"
    },
  ]
  
  const error = null
  const loading = false
  const listResponse = {items: [
    {
      id: 1,
      Title: "Project 1",
      // Code: "ABCDEF",
      Scope: "Education & Training",
      Status: "Open"
    },
    {
      id: 2,
      Title: "Project 2",
      // Code: "GHIJKL",
      Scope: "Clinical program development / improvement",
      Status: "Open"
    },
    {
      id: 3,
      Title: "Project 3",
      // Code: "MNOPQR",
      Scope: "Research & Development",
      Status: "Open"
    },
    {
      id: 4,
      Title: "Project 4",
      // Code: "STUVWX",
      Scope: "Medical physics, clinician, and technologist programs",
      Status: "Open"
    },
    {
      id: 5,
      Title: "Project 5",
      // Code: "YZABCD",
      Scope: "Clinical support through visiting faculty",
      Status: "Open"
    },
    {
      id: 6,
      Title: "Project 6",
      // Code: "ABCDEF",
      Scope: "Equipment transfer/ donation",
      Status: "Open"
    },
    {
      id: 7,
      Title: "Project 7",
      // Code: "ABCDEF",
      Scope: "Education & Training",
      Status: "Open"
    },
    {
      id: 8,
      Title: "Project 8",
      // Code: "ABCDEF",
      Scope: "Clinical support through visiting faculty",
      Status: "Open"
    },
    {
      id: 9,
      Title: "Project 9",
      // Code: "ABCDEF",
      Scope: "Research & Development",
      Status: "Open"
    },
    {
      id: 10,
      Title: "Project 10",
      // Code: "ABCDEF",
      Scope: "Education & Training",
      Status: "Open"
    },
  ], count: 0}
  const list = listResponse?.items ?? []
  const count = listResponse?.count ?? 0
  const totalPages = Math.ceil(count / pageSize)
  if (
    props.isMaster &&
    props.autoSelect &&
    !loading &&
    !isAdd &&
    !entityId
  ) {
    props.onSelect(list[0]?.id ?? 0)
  }

  if (error) return <p>Error : {error.message}</p>;

  return (
    <OptionsMenu isOpen={isFilterMenuOpen}>
      <OptionsMenu.Menu>
        <div className="options-header" >
          <button
            onClick={() => {
              setOptionSortDirection("DESC")
              setOptionSortBy("CREATEDAT_")
              setOptionStartDate(null)
              setOptionEndDate(null)
            }}
            style={{marginRight: "10px"}}
          >
            Clear Filters
          </button>
          <button
            onClick={() => setIsFilterMenuOpen(false)}
          >
            Close
          </button>
        </div>

        <div className="options-section">
          <h3>Filters</h3>

          <div className="option">
            <label>Start Date:</label>
            <DateTimeInput
              dateOnly
              value={optionStartDate}
              onChange={setOptionStartDate}
              timeZone='America/New_York'
            />
          </div>

          <div className="option">
            <label>End Date:</label>
            <DateTimeInput
              dateOnly
              value={optionEndDate}
              onChange={setOptionEndDate}
              timeZone='America/New_York'
            />
          </div>

        </div>

        <div className="options-section">
          <h3>Sorting</h3>

          <div className="option">
            <label>Sort By:</label>
            <div>
              <select
                value={optionSortBy}
                onChange={event => setOptionSortBy(event.target.value)}
              >
                {sortByOptions.map(option => (
                  <option key={option.text} value={option.sortBy}>{option.text}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="option">
            <label>Sort Order:</label>
            <div>
              <select
                value={optionSortDirection}
                onChange={event => setOptionSortDirection(event.target.value)}
              >
                {sortDirectionOptions.map(option => (
                  <option key={option.text} value={option.sortDirection}>{option.text}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="options-section">
          <button
            onClick={() => {
              setListOptions({
                page: 1,
                sortBy: optionSortBy,
                sortDirection: optionSortDirection,
                startDate: optionStartDate,
                endDate: optionEndDate,
              })
              setIsFilterMenuOpen(false)
            }}
          >
            {loading ?
              "...loading"
              :
              "Apply"
            }
          </button>
        </div>
      </OptionsMenu.Menu>
      <OptionsMenu.Layout>
        <div className="page-header">
          <span className="page-header-title">Projects</span>
          <div>
            {props.isMaster &&
              <button
                onClick={() => props.onSelect(0)}
                style={{marginRight: "10px"}}
              >
                Add
              </button>
            }
            <button
              onClick={() => setIsFilterMenuOpen(true)}
            >
              Options
            </button>
          </div>
        </div>

        <div>
          {loading ? <LoadingView/> : list.length > 0 ?
            <ListView
              items={list.map((entity, index) => ({
                key: entity.id,
                selected: String(entity.id) === String(entityId) && !isAdd,
                onClick: () => props.onSelect(entity.id),
                ...entity,
                id: null
              }))}
            />
            :
            <div>
              <p className="no-results">No results for this selection</p>
            </div>
          }
        </div>

        <div>
        {totalPages < 2 ? null :
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onChangePage={(newPage) => {
              setListOptions({page: newPage})
            }}
            pageSize={pageSize}
            onChangePageSize={(size) => {
              setListOptions({pageSize: Number(size)})
            }}
          />
        }
        </div>
      </OptionsMenu.Layout>
    </OptionsMenu>
  )
}
