import {gql, useMutation, useQuery} from "@apollo/client";
import React, {useState} from "react";
import {LoadingView, Button} from "@solsys/solsys-reactjs";
import styled from 'styled-components';

const entityQuery = gql`
  query MedicalPhysicsEquipment($listRequest: medical_physics_equipment_list_request) {
    medical_physics_equipment(medical_physics_equipment_list_request: $listRequest) {
      edges {
        id
        name
        description
      }
      totalCount
    }
  }
`

const entityMutation = gql`
  mutation medical_physics_equipmentMutate($medical_physics_equipment_mutation_request: MutationInput) {
    medical_physics_equipment(medical_physics_equipment_mutation_request: $medical_physics_equipment_mutation_request) {
      id
      isNew
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  margin-bottom: 20px;
`;

const Label = styled.p`
  font-size: 16px;
  color: #6c757d;
  margin: 0 10px;
`;

const Count = styled.p`
  font-size: 24px;
  color: #6c757d;
  font-weight: bold;
  margin: 0 10px 0 0;
`;

const CountItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

export default function MedicalPhysicsEquipmentEdit({
  id: entityId,
  action,
  navigate,
}) {
  const isEdit = action === 'edit'

  // variables for entity query const
  let variables = {
    listRequest: {
      filters: [
        {
          field: "id",
          operator: "=",
          value: String(entityId)
        }
      ]
    }
  }

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  
  // fetches entity and sets up refetch
  const {
    data,
    loading: loadingEntity,
    refetch: refetchEntity,
  } = useQuery(entityQuery, {
    variables,
    skip: !isEdit,
    fetchPolicy: 'network-only',
    onCompleted: (localData) => {
      const listResponse = localData?.medical_physics_equipment
      const list = listResponse?.edges ?? []
      const medical_physics_equipment = list[0]
      setName(medical_physics_equipment?.name)
      setDescription(medical_physics_equipment?.description)
    },
    onError: error => {
      console.error(error)
    }
  });
  const medical_physics_equipment = (data?.medical_physics_equipment?.edges ?? [])[0]
  
  // sets up mutation
  const [mutateFunction, mutateInfo] = useMutation(entityMutation, {
    variables: {medical_physics_equipment_mutation_request: {
        id: entityId,
        properties: [
          {key: "name", string: name},
          {key: "description", string: description},
        ]
      }},
    onError: error => {
      console.error(error)
    },
    onCompleted: (response, originalRequestVariables) => {
      let id = response?.medical_physics_equipment?.id
      navigate({
        action: 'edit',
        id,
        push: false
      })
      refetchEntity()
    }
  })
  
  let loadingMutation = mutateInfo.loading
  
  return loadingEntity ? <LoadingView/> : (
    <>
      <div className="page-header">
        <span className="page-header-title">{isEdit ? 'Edit':'Add'} MedicalPhysicsEquipment</span>
      </div>

      <div className="add-edit">
        <div className="edit-section">
          <div className="edit-property">
            <label>Name</label>
            <input
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>

        <div className="edit-property">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>

        <div className='edit-section'>
          <Button
            disabled={loadingMutation}
            onClick={() => {
              mutateFunction()
            }}
            loading={loadingMutation}
          >Save</Button>
        </div>

      </div>
    </>
  )
}
