import React, {useEffect, useState} from "react";
import DropDown from "./DropDown";
import {gql, useLazyQuery} from "@apollo/client";

const gqlQuery = gql`
  query resourceList($resource_list_request: resource_list_request) {
    resource(resource_list_request: $resource_list_request) {
      edges {
        id
        name
        description
      }
      totalCount
    }
  }
`;

const renderGqlQuery = gql`
  query resourceRenderList($resource_list_request: resource_list_request) {
    resource(resource_list_request: $resource_list_request) {
      edges {
        id
        name
      }
      totalCount
    }
  }
`;

const entityMap = e => ({...e})

const ResourceDropDown = ({selected, ...props}) => {
  
  const truncateLength = 25;

  const [requestState, setRequestState] = useState({
    page: 1,
    pageSize: 20,
    search: ""
  })
  const {page, pageSize, search} = requestState
  
  let variables = {
    resource_list_request: {
      pagination: {
        limit: pageSize,
        offset: (page - 1) * pageSize,
      },
      filters: []
    }
  }
  
  let renderVariables = {
    resource_list_request: {
      pagination: {
        limit: truncateLength,
        offset: 0
      },
      filters: [],
      sorting: [{field: "name", direction: "ASC"}]
    }
  }
  
  if((Array.isArray(selected) && selected.length > 0) || Number.isInteger(selected)) {
    let array = [];
    if(props.multiple) {
      selected.forEach(v => array.push(v))
    } else {
      array.push(selected)
    }
    renderVariables.resource_list_request.filters.push({
      field: "id",
      operator: "in",
      value: `(${array.join(", ")})`
    })
  }

  if (search && search !== "") variables.resource_list_request.filters.push({
    field: "name",
    operator: "like",
    value: `%${search}%`,
  })
  if(props.filters?.length > 0) {
    props.filters.forEach(f=> {
      if(f.value) variables.resource_list_request.filters.push(f)
    })
  }
  if(props.sorting?.length > 0) {
    props.sorting.forEach(s=> variables.resource_list_request.sorting = [s])
  }
  
  let [renderRefetch, renderObject] = useLazyQuery(renderGqlQuery, {variables: renderVariables});
  const renderLoading = renderObject.loading;
  const renderError = renderObject.error;
  const renderData = renderObject.data;
  
  useEffect(() => renderRefetch(), [selected, renderRefetch])
  
  const renderEntityListResponse = renderData?.resource
  const renderEntity = renderEntityListResponse?.edges ?? []
  
  const [refetch, { loading, error, data }] = useLazyQuery(gqlQuery, {variables});
  useEffect(() => {
    if (!props.disabled) refetch()
  }, [page, search, refetch, props.disabled])
  
  const onSearch = (event) => {
    let {value} = event.target
    setRequestState({
      ...requestState,
      search: value
    })
  }
  
  const paginationChange = (newPage) => {
    setRequestState({
      ...requestState,
      page: newPage
    })
  }
  
  const entityListResponse = data?.resource
  const entityList = entityListResponse?.edges ?? []
  const entityCount = entityListResponse?.totalCount ?? 0
  
  if(error) console.error(`Resource drop down data error : ${error.message}`);
  if(renderError) console.error(`Resource drop down render error : ${renderError.message}`)
  
  return (
    <DropDown
      items={entityList.map(entityMap)}
      loading={loading}
      renderLoading={renderLoading}
      multiple={props.multiple}
      searchEnabled={props.searchEnabled}
      searchValue={search}
      onSearch={onSearch}
      selectValue={selected}
      selectRender={renderEntity.map(entityMap)}
      onSelect={props.onChange}
      dataKey={"id"}
      textKey={"name"}
      subtextKey={"description"}
      truncateLength={truncateLength}
      paginationInfo={{currentPage: page, totalPages: entityCount/pageSize}}
      entityCount={entityCount}
      onPaginationChange={paginationChange}
      {...props}
    />
  )
}

export default ResourceDropDown;
