import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import 'leaflet/dist/leaflet.css';

// Import the default marker icon URLs
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

// Create two different Leaflet icons for source and destination
let sourceIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

let destinationIcon = new L.Icon({
  // Replace these URLs with the URLs to your custom marker icons
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

const AntLines = ({ lines }) => {
  const map = useMap();

  React.useEffect(() => {
    lines.forEach((line) => {
      antPath([line.from, line.to], {color: '#245b05', weight: 2, opacity: 0.6, delay: 400}).addTo(map);
    });
  }, [map, lines]);

  return null;
};

const MapViewComponent = () => {
  // Array of source marker points
  const sourcePoints = [
    {name: "Source 1", location: [34.0522, -118.2437]},
    {name: "Source 2", location: [39.7392, -104.9903]},
    {name: "Source 3", location: [43.6150, -116.2023]},
    {name: "Source 4", location: [41.8781, -93.0977]},
    {name: "Source 5", location: [45.5051, -122.6750]},
    {name: "Source 6", location: [47.6101, -122.2015]},
  ];

  // Array of destination marker points
  const destinationPoints = [
    {name: "Destination 1", location: [-18.87919, 47.50791]},
    {name: "Destination 2", location: [-13.1339, 27.8493]},
    {name: "Destination 3", location: [-38.4161, -63.6166]},
    {name: "Destination 4", location: [39.9042, 116.4074]},
    {name: "Destination 5", location: [59.9139, 10.7522]},
    {name: "Destination 6", location: [64.9631, -19.0208]},
    {name: "Destination 7", location: [-25.2744, 133.7751]},
    {name: "Destination 8", location: [-40.9006, 174.8860]},
    {name: "Destination 9", location: [56.1304, -106.3468]},
    {name: "Destination 10", location: [-30.5595, 22.9375]},
  ];

  // Array of lines between source and destination points
  const lines = [
    {from: sourcePoints[0].location, to: destinationPoints[0].location},
    {from: sourcePoints[1].location, to: destinationPoints[2].location},
    {from: sourcePoints[2].location, to: destinationPoints[4].location},
    {from: sourcePoints[3].location, to: destinationPoints[6].location},
    {from: sourcePoints[4].location, to: destinationPoints[8].location},
  ];

  return (
    <MapContainer center={[25, -25]} zoom={3} style={{ height: "calc(100vh - 40px)", width: "100%" }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        sourcePoints.map((point, index) =>
          <Marker key={`s${index}`} position={point.location} icon={sourceIcon}>
            <Popup>{point.name}</Popup>
          </Marker>
        )
      }
      {
        destinationPoints.map((point, index) =>
          <Marker key={`d${index}`} position={point.location} icon={destinationIcon}>
            <Popup>{point.name}</Popup>
          </Marker>
        )
      }
      <AntLines lines={lines} />
    </MapContainer>
  );
}

export default MapViewComponent;