import React, { useState, useContext } from 'react';
import {gql, useQuery, useMutation} from '@apollo/client'
import styled from 'styled-components';
import { FaPaperPlane, FaExchangeAlt, FaShare, FaCog } from 'react-icons/fa';
import {LoadingView, NotificationContext, SolsysAuthReactContext} from '@solsys/solsys-reactjs'
import StatusDropDown from './StatusDropDown'
import {DateTime} from 'luxon'

// Styled Components
const ChatContainer = styled.div`
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  //overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  margin: 10px auto 120px;
`;

const MessageBubble = styled.div`
  background-color: #fff;
  color: #333;
  padding: 12px 18px;
  border-radius: 20px;
  margin: 8px 0;
  max-width: 70%;
  align-self: ${({ own }) => own ? 'flex-end' : 'flex-start'};
  color: ${({own}) => own ? 'white':'initial'};
  background-color: ${({own}) => own ? '#538cff':'initial'};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  
  .changes {
    background-color: #ddf;
    color: #111;
    margin-top: 6px;
    padding: 6px;
    border-radius: 8px;
    font-style: italic;
    color: ${({own}) => own ? 'white':'initial'};
  }
  
  .time {
    font-size: 11px;
    font-style: italic;
    margin-top: 4px;
    color: #666;
    color: ${({own}) => own ? 'white':'initial'};
  }
`;

const ChangesBubble = styled.div`
  margin-top: -6px;
  max-width: 70%;
  align-self: ${({ own }) => own ? 'flex-end' : 'flex-start'};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
`;

const MessageInput = styled.div`
  //display: flex;
  //align-items: center;
  background-color: #e8e8e8;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 8px 10px;
  border-radius: 20px;
  background: #fff;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #4a4a4a;
  &:hover {
    transform: translateY(-2px);
  }
`;

const ButtonLabel = styled.span`
  margin-left: 5px;
`;

// const StatusDropdown = styled.select`
//   padding: 5px;
//   border-radius: 5px;
//   border: 1px solid #ccc;
//   margin-right: 10px;
//   width: 120px;
// `;

const ControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

const ControlLabel = styled.div`
  font-size: 14px;
  color: #333;
  margin-right: 10px;
`;

const entityQuery = gql`
  query ProjectUpdates($listRequest: project_update_list_request, $projectFilter: project_list_request) {
    project_update(project_update_list_request: $listRequest) {
      edges {
        id
        createdAt
        message
        changes
        user_display_name
        user_id
        project {
          status_id
        }
      }
    }
    project(project_list_request: $projectFilter) {
      edges {
        status_id
        status {
          name
        }
      }
    }
  }
`

const entityMutation = gql`
  mutation projectUpdateMutate($request: MutationInput) {
    project_update(project_update_mutation_request: $request) {
      id
      isNew
    }
  }
`
const projectMutation = gql`
  mutation projectMutate($request: MutationInput) {
    project(project_mutation_request: $request) {
      id
      isNew
    }
  }
`

// Chat Component
const Chat = ({project_id}) => {
  const {addNotification} = useContext(NotificationContext)
  const {user} = useContext(SolsysAuthReactContext)
  const [newMessage, setNewMessage] = useState('');
  const [status, setStatus] = useState()
  const [statusName, setStatusName] = useState()
  console.log(user)

  // variables for entity query const
  let variables = {
    listRequest: {
      filters: [
        {
          field: "project_id",
          operator: "=",
          value: String(project_id)
        }
      ],
      sorting: {
        field: 'id',
        direction: 'asc'
      }
    },
    projectFilter: {
      filters: [
        {
          field: "id",
          operator: "=",
          value: String(project_id)
        }
      ]
    }
  }

  // fetches entity and sets up refetch
  const {
    data,
    loading: loadingEntity,
    refetch: refetchEntity,
  } = useQuery(entityQuery, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: localData => {
      const listResponse = localData?.project
      const list = listResponse?.edges ?? []
      const project = list[0]
      // setStatus(project?.status_id)
    },
    onError: error => {
      console.error(error)
    }
  });
  const updates = data?.project_update?.edges ?? []
  const project = data?.project?.edges?.[0]

  // sets up mutation
  const [mutateFunction, mutateInfo] = useMutation(entityMutation)
  const [mutateProjectFunction, mutateProjectInfo] = useMutation(projectMutation)

  let loadingMutation = mutateInfo.loading

  const sendMessage = () => {
    let promises = Promise.resolve()
    let changes = ''
    if (!!status) {
      changes += `Status changed from ${project?.status?.name} to ${statusName}`
      promises = promises.then(() => mutateProjectFunction({variables: {request: {
        id: project_id,
        properties: [
          {key: 'status_id', number: status},
        ]
      }}}))
    }
    if (newMessage.trim() !== '' || !!status) {
      promises = promises.then(() => mutateFunction({variables: {request: {properties: [
        {key: 'message', string: newMessage},
        ...(changes === '' ? []:[{key: 'changes', string: changes}]),
        {key: 'project_id', number: project_id},
        {key: 'user_id', string: user._id},
        {key: 'user_display_name', string: user.name ?? user.username},
      ]}}}))
    }

    return promises
      .then(response => {
        refetchEntity()
        setNewMessage('')
        setStatus(null)
        setStatusName(null)
      })
      .catch(error => {
        addNotification('bad', 'Something went wrong.')
        console.error(error)
      })
  };

  return loadingEntity ? <LoadingView/> : (
    <ChatContainer>
	    <h3>History</h3>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {updates.map(update => (<React.Fragment key={update.id}>
          <MessageBubble own={update.user_id === user._id}>
            <div className='message'>{update.message}</div>
            {update.changes &&
              <div className='changes'>{update.changes}</div>
            }
            {update.createdAt &&
              <div className='time'>{update.user_display_name ? `${update.user_display_name} - `:''}{DateTime.fromISO(update.createdAt).toFormat('MMM d, h:mm a')}</div>
            }
          </MessageBubble>
        </React.Fragment>))}
      </div>
      <ControlPanel>
        {/*<div style={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*  <ControlLabel>Change Status:</ControlLabel>*/}
        {/*  <StatusDropDown*/}
        {/*    selected={status}*/}
        {/*    onChange={setStatus}*/}
        {/*    filters={[{*/}
        {/*      field: 'id',*/}
        {/*      operator: '!=',*/}
        {/*      value: String(project?.status_id)*/}
        {/*    }]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<IconButton title="Transfer"><FaExchangeAlt /><ButtonLabel>Transfer</ButtonLabel></IconButton>*/}
        {/*<IconButton title="Share"><FaShare /><ButtonLabel>Share</ButtonLabel></IconButton>*/}
        {/*<IconButton title="Options"><FaCog /><ButtonLabel>Options</ButtonLabel></IconButton>*/}
      </ControlPanel>
      <MessageInput>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px', justifyContent: 'space-between'}}>
          <h4 style={{margin: 0}}>Update Project</h4>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
            <ControlLabel>Change Status:</ControlLabel>
            <StatusDropDown
              selected={status}
              onChange={(id, status) => {
                setStatus(id)
                if (id) {
                  setStatusName(status.name)
                } else {
                  setStatusName(null)
                }
              }}
              filters={[{
                field: 'id',
                operator: '!=',
                value: String(project?.status_id)
              }]}
            />
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          />
          <IconButton
            onClick={sendMessage}
            title="Send"
            disabled={newMessage === '' && (status === project?.status_id || !status)}
          >
            <FaPaperPlane/><ButtonLabel>Submit</ButtonLabel>
          </IconButton>
        </div>
      </MessageInput>
    </ChatContainer>
  );
};

export default Chat;


