import React, { useState, useContext } from 'react';
import {withRouter} from 'react-router';
import styled from 'styled-components'
import {useQuery, gql} from '@apollo/client';
import {DateTime} from 'luxon';

const TableStyle = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: white;
  td, th {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    //color: ba;
  }
  thead > tr {
    cursor: default;
    background-color: #f9f9f9;
  }
  .scope {
    max-width: 100px;
  }
`;

const CardStyle = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
  }
`

const GroupOptions = [{
  id: 'status',
  name: "Status",
  displayKey: "name",
  groupByFilter: (project, status) => project.status_id === status.id
}, {
  id: 'scope',
  name: "Scope",
  displayKey: 'name',
  groupByFilter: (project, scope) => project.project2scope?.edges?.find(p2s => p2s?.scope?.id === scope.id)
}]

const NoResults = () => (
  <div style={{color: 'darkgrey'}}>no results</div>
)

const ProjectView = () => {
  // Define state for current view { list, board }
  const [view, setView] = useState('list');
  const [groupId, setGroupId] = useState('status');
  const group = GroupOptions.find(v => v.id === groupId)

  const optionData = {}

  const {data: projectData} = useQuery(gql`
    query ProjectList {
      project {
        edges {
          id
          title
          objective
          created
          start_date
          end_date
          status_id
          status {
            name
          }
          project2scope {
            edges {
              scope {
                id
                name
              }
            }
          }
        }
      }
    }
  `)
  const projects = projectData?.project?.edges ?? []

  const {data: scopeData} = useQuery(gql`
    query ScopeList {
      scope {
        edges {
          id
          name
        }
      }
    }
  `)
  optionData.scope = scopeData?.scope?.edges?.toReversed() ?? []

  const {data: statusData} = useQuery(gql`
    query StatusList {
      status {
        edges {
          id
          name
        }
      }
    }
  `)
  optionData.status = statusData?.status?.edges?.toReversed() ?? []

  // Switch views
  const switchView = () => {
    setView(view => view === 'list' ? 'board' : 'list');
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        height: '60px',
        padding: '0 20px',
        background: '#eee',
      }}>
        <b>Options</b>
        <button onClick={switchView}>View as {view === 'list' ? 'Board' : 'List'}</button>
        <span>Group By:</span>
        <select value={group.id} onChange={e => setGroupId(e.target.value)}>
          {GroupOptions.map(groupOption => (
            <option key={groupOption.id} value={groupOption.id}>{groupOption.name}</option>
          ))}
        </select>
      </div>
      <div style={{
        padding: '20px',
        display: "flex",
        flexDirection: view === 'list' ? 'column':'row',
        overflow: 'scroll',
        gap: '10px',
        alignItems: view === 'list' ? 'stretch':'start',
        minHeight: 'calc(100vh - 100px)'
      }} >
        {view === 'list' ?
          <ListView
            projects={projects}
            options={optionData[group.id]}
            group={group}
          /> :
          <BoardView
            projects={projects}
            options={optionData[group.id]}
            group={group}
          />
        }
      </div>
    </div>
  );
}

// Display project as list
const ListView = withRouter(({ projects, options, group, history }) => (
  <>
    {options.map(option => (
      <TabbedFileFolder title={option[group.displayKey]}>
        {projects.filter(v => group.groupByFilter(v,option)).length === 0 ? <NoResults/> :
          <TableStyle>
            <thead>
            <tr>
              <th>Title</th>
              <th>Objective</th>
              <th className='status'>Status</th>
              <th className='scope'>Scope</th>
              <th>Created</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
            </thead>
            <tbody>
            {projects.filter(v => group.groupByFilter(v,option)).map(project => (
              <tr
                key={project.id}
                onClick={() => {
                  history.push('/project/' + project.id)
                }}
                style={{cursor: 'pointer'}}
              >
                <td>{project.title}</td>
                <td>{project.objective}</td>
                <td className='status'>{project.status?.name}</td>
                <td className='scope'>{project.project2scope?.edges?.map(p2s => p2s.scope.name).join(', ')}</td>
                <td>{DateTime.fromISO(project.created).toFormat('MM/dd/yyyy')}</td>
                <td>{!project.start_date ? "Not Set" : DateTime.fromISO(project.start_date).toFormat('MM/dd/yyyy')}</td>
                <td>{!project.end_date ? "Not Set" : DateTime.fromISO(project.end_date).toFormat('MM/dd/yyyy')}</td>
              </tr>
            ))}
            </tbody>
          </TableStyle>
        }
      </TabbedFileFolder>
    ))}
  </>
));

// Display project as board
const BoardView = withRouter(({projects, options, group, history}) => (
  <>
    {options.map(option => (
      <div style={{
        background: '#eaf2ff',
        width: '300px',
        minWidth: '300px',
        padding: '10px',
        borderRadius: '8px'
      }}>
        <h4 style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{option.name}</h4>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}>
          {projects.filter(v => group.groupByFilter(v,option)).length === 0 ? <NoResults/> : projects.filter(v => group.groupByFilter(v,option)).map(project => (
            <CardStyle
              key={project.id}
              style={{
                background: 'white',
                padding: '8px',
                borderRadius: '8px',
                cursor: 'pointer'
              }}
              onClick={() => {
                history.push('/project/' + project.id)
              }}
            >
              <div><b>Title</b> {project.title}</div>
              <div><b>Objective</b> {project.objective}</div>
              <div><b>Status</b> {project.status?.name}</div>
              <div><b>Created</b> {DateTime.fromISO(project.created).toFormat('MM/dd/yyyy')}</div>
              <div><b>Start Date</b> {!project.start_date ? "Not Set" : DateTime.fromISO(project.start_date).toFormat('MM/dd/yyyy')}</div>
              <div><b>End Date</b> {!project.end_date ? "Not Set" : DateTime.fromISO(project.end_date).toFormat('MM/dd/yyyy')}</div>
            </CardStyle>
          ))}
        </div>
      </div>
    ))}
  </>
));

const TabbedFileFolder = ({title, children}) => {
  return (
    <div style={styles.folder}>
      <div style={styles.tabs}>
        <div style={styles.tab}>
          <h4 style={{margin: 0}}>{title}</h4>
        </div>
      </div>
      <div style={styles.content}>
        {children}
      </div>
    </div>
  );
};

// Styles for the component
const styles = {
  folder: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#eaf2ff',
  },
  tabs: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
  },
  tab: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '3px solid transparent',
  },
  activeTab: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '3px solid blue',
  },
  content: {
    padding: '10px',
  }
};

export default withRouter(ProjectView);