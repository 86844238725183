import React, {useEffect, useRef, useState} from "react"
import ProfessionList , {ListOptions} from "./ProfessionList"
import ProfessionEdit from "./ProfessionEdit"
import {MDSplitView} from "@solsys/solsys-reactjs"

export default function ProfessionController({
  navigate,
  action,
  id,
  search,
  style,
  options = {}
}) {
  let listState = {
    [ListOptions.sortBy]: 'id',
    [ListOptions.sortDirection]: "DESC",
    [ListOptions.startDate]: null,
    [ListOptions.endDate]: null,
  }
  const didMount = useRef(false)
  useEffect(() => didMount.current ? undefined:(didMount.current = true))
  if (!didMount.current && Object.keys(search).length) {
    Object.keys(ListOptions).forEach(key => {
      listState[key] = search[key]
    })
  }
  listState = useState(listState)

  return (
    <div className='profession'>
      <MDSplitView
        globalName='ProfessionMDV'
        style={style}
        action={action}
        master={({isNarrow, autoSelect}) => <ProfessionList
          isMaster
          state={listState}
          // autoSelect={autoSelect}
          id={id}
          action={action}
          onStateChange={state => {
            navigate({
              action,
              id,
              search: state,
              push: false,
            })
          }}
          onSelect={(newId, userInteraction) => {
            if (newId && id && String(newId) === String(id) && userInteraction) {
              navigate({
                popOut: isNarrow,
                action: 'list',
                id: null,
                search: listState[0]
              })
            } else {
              navigate({
                popOut: isNarrow,
                ...newId === 0 ? {
                  action: 'add',
                  id: null,
                } : {
                  action: 'edit',
                  id: newId
                },
                search: listState[0]
              })
            }
          }}
        />}
        detail={() => <ProfessionEdit
          key={`action_${action}_id_${id}`}
          id={id}
          action={action}
          navigate={navigate}
        />}
        initialWidth={300}
        maxWidth={650}
        minWidth={280}
      />
    </div>
  )
}
